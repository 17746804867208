/* DuringPlanning.css */
.post-planning {
  display: flex;
  background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    height: 100%;
}

.post-planning-box {
  margin: 0;
  padding: 0;

}

.post-planning-content {
  background-color: var(--aqua);
  border: 1px solid var(--darkbluesemi);
  border-top: none;
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  padding: 3rem;
}
/* Hide scrollbar for all browsers */
.main-content {
  overflow: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.main-content::-webkit-scrollbar {
  display: none;
}