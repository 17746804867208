.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.hidden {
  display: none;
}

.popup-container {

  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  max-height: 100%;
  max-width: 580px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: "Quicksand";
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.popup-header {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  background: #F1F9FF;
  border-bottom: 2px solid #ddd;
  border: 5px;
}

.popup-header p {
  font-size: 20px;
  font-weight: 600;
  color: #333;
}

.popup-header img {
  height: 30px;
  margin-bottom: 0px;
}

.popup-close {
  background: none;
  border: none;
  font-size: 26px;
  display: flex;
margin-top: -5px;
  cursor: pointer;
  color: #333;
}

.popup-body {
  margin-top: 20px;
}

.emission-summary {
  /* outline: 1px solid red; */
  display: flex;

  justify-content: space-between;
  gap: 5px;
  background: #ffffff;
  padding: 10px;
}

.emission-summary-details {
    display: flex;
    width: 90%;  
    padding: 5px;
    flex-direction: column;
  text-align: left;
  /* outline: 1px solid green; */
}

.emission-summary-details p{
    font-size: 18px;
    text-align: left;
    font-weight: 500;
  color: #437298;

}


.total-emission {
  font-size: 24px;
  color: #437298;
}

.bar-container {
  position: relative;
 width: 100%;

 
}

.bar-container .skill-box {
  width: 100%;
  margin: 12px 0;
}



.skill-box {
  width: 100%;
  margin: 15px 0; /* Consistent margin for better spacing */
  padding: 0 5px; /* Adds inner padding for alignment */
  position: relative;
}


.bar-title {
  display: block;
  margin-bottom: 14px; /* Adjust this value as needed */
  font-size: 14px;
  font-weight: bold;
}

.bar-text{
 
  font-size: 12px;
  margin: 0; /* Reset margins */
  padding: 0; /* Reset padding */
  white-space: nowrap; /* Prevent text from breaking */
  font-style: italic;
  color: #333;
  line-height: 20px; /* Match .skill-bar height */
  
 

}
.skill-bar {
  position: relative;
  height: 20px; /* Increased height for better visibility */
  width: 100%;
  background: #f0f0f0; /* Background for unfilled part of the bar */
  border-radius: 6px;
  overflow: hidden; /* Ensures content does not overflow the bar */
  margin-top: 5px;
}

.skill-per {
  display: grid;
  place-items: center end; /* Center vertically, align text to the right */
 
  height: 100%;
  line-height: 30px;
  border-radius: 6px;
  background: #FFD5F7; /* Example color; adjust per bar */
  position: relative;
  white-space: nowrap; /* Prevents the text from breaking onto another line */
  padding-right: 5px; /* Adds some space between text and the bar's edge */
  color: #333; /* Text color inside the bar */
  font-size: 12px;
  font-style: italic;
  font-weight: bold;
  transition: width 0.4s ease-in-out;
}

.screenshot-mode .bar-text {
  transform: translateY(-50%); /* Adjust alignment for screenshots */
}


.bar-container .skill-box {
  margin: 15px 0; /* Consistent spacing between bars */
}


@keyframes progress {
  0% {
    width: 0;
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}



  
  
.info-event{
  display: flex;
  justify-content: space-between;
  gap: 5px;
  background: #ffffff;
 
  padding: 10px;
}
.info-event-details{
  width: 100%; 

}

.info-event-details h4 {
  font-size: 16px;
  margin-bottom: 5px;
  text-align: left;
  color: #286ea7;
}

.info-event-details p {
  font-size: 14px;
  text-align: left;
  font-weight: 500;
}




.popup-footer {
  display: flex;
  padding: 10px;
  height: 60px;
 
  margin-top: 2px;
  justify-content: space-between;
  align-items: center;
  background: #F1F9FF;
}

.popup-text p {
  font-size: 14px;
  margin-top: -20px;
text-align: center;
  line-height: 1.5;
}

.popup-text p strong {
  font-size: 1.5rem;
  font-weight: bold;
}

.popup-text p + p {
  font-style: italic;
  margin-top: 10px;
}


.share-container {
  position: relative;
}

.share-card {
  position: absolute; 
  top: 40px; 
  left: 0;
  width: fit-content;
  background-color: rgba(49, 49, 49, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 15px;
  border-radius: 27px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.055);
  z-index: 10;
}

.share-card  a {
  width: 40px;
  height: 40px;
  background-color: rgba(117, 117, 117, 0);
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.15, 0.83, 0.66, 1);
}

.share-card  a:hover {
  transform: scale(1.2);
  border-radius: 15px;
}
/*instagram*/
.share-card  a:nth-child(1):hover {
  background-color: #d62976;
}
/* twitter*/
.share-card  a:nth-child(2):hover {
  background-color: #00acee;
}
/* linkdin*/
.share-card  a:nth-child(3):hover {
  background-color: #0072b1;
}
/* Whatsapp*/
.share-card  a:nth-child(4):hover {
  background-color: #25d366;
}

.share-card  a:active {
  transform: scale(1.1);
  transition-duration: 0.3s;
}

/*first-child*/
.share-card  a svg:first-child {
  display: flex;
  scale: 1.3;
  position: absolute;
  width: 17px;
  opacity: 1;
  transform: translateY(0px);
  transition: all 0.4s cubic-bezier(0.15, 0.83, 0.66, 1);
}

.share-card  a svg:first-child path {
  fill: rgb(255, 255, 255);
}

.share-card  a:hover svg:first-child {
  transform: translateY(-40px);
  opacity: 0;
}
/*last-child*/
.share-card  a svg:last-child {
  display: flex;
  position: absolute;
  width: 17px;
  transform: translateY(20px);
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.15, 0.83, 0.66, 1);
}
.share-card  a svg:last-child path {
  fill: rgb(255, 255, 255);
}

.share-card  a:hover svg:last-child {
  opacity: 1;
  transform: translateY(0px);
}


.sign-up-button,
.download-button,
.share-button {
  padding: 10px 20px;
  margin-top: -2px;
  border-radius: 5px;
  border: none;
  font-size: 14px;
  cursor: pointer;
}

.sign-up-button {
  background: none;
  border: 1px solid grey;
  color: #363b4c;
}

.download-button {
  background: none;
  border: 1px solid grey;
  color: grey;
  color: #363b4c;
  margin-right: 10px;
  margin-left: 20px;
}

.share-button {
  background: #464D63;
  border: 1px solid grey;
  color: rgb(255, 255, 255);
}


.hidden {
  display: none !important;
}


@media (max-width: 768px) {
  .popup-container {
    width: 90%; 
    padding: 15px; 
    max-width: none; 
  }

  .popup-header p {
    font-size: 18px; 
  }

  .popup-body {
    margin-top: 15px; 
  }

  .emission-summary {
    flex-direction: column; 
    gap: 10px; 
  }

  .emission-summary-details p {
    font-size: 16px; 
  }

  .popup-footer {
    flex-direction: column; 
    gap: 10px; 
    height: auto; 
    padding: 15px 10px;
  }

  .share-card {
    top: 40px; 
 
    width: 200px;

    
  }

  .share-card a {
    width: 30px; 
    height: 30px; 
  }

  .info-event {
    flex-direction: column; 
    gap: 15px; 
  }

  .info-event-details h4,
  .info-event-details p {
    font-size: 14px;
  }

  .skill-bar {
    height: 15px; 
  }

  .popup-text p {
    font-size: 12px; 
    line-height: 1.4; 
  }

  

}

@media (max-width: 480px) {
  .popup-container {
    width: 95%; 
    padding: 10px; 
  }

  .popup-header p {
    font-size: 16px;
  }

  .emission-summary-details p {
    font-size: 14px; 
  }

  .popup-footer {
    padding: 10px 5px; 
  }

 
  .popup-text p {
    font-size: 10px; 
  }
}
