.popup-overlay {
    font-family: "Quicksand";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    width: 700px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.popup-content h2{
    font-size: 24px;
   font-weight: bold;
}

.popup-close-button {
    position: absolute;
    top: 7px;
    right: 20px;
    background: transparent;
    border: none;
    font-size: 40px;
    cursor: pointer;
}

.Wefix-contact-image {
    width: 390px;
    height: 150px;
    margin-bottom: 15px;
}

.wefix-pop-contact {
    margin-right: 100%;
    display: flex;
}

.popup-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.popup-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
}

.wefixspeedometer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
    border-right: 1px solid #ccc;
}

.Wefix-para {
    margin-top: 10px;
    text-align: center;
}


.wefixemission-message {
    margin-top: -100px;
    font-size: 14px;
    text-align: center;
    color: #333;
}

.wefix-pop-container h2{
    color: var(--darkblue);
    font-family: "quicksand";
}
.wefix-pop-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.Wefix-img33 img {
    width: 650px;
    height: 200px;
}

.Wefix-para p {
    font-size: 100%;
    font-weight: bold;
}

.wefix .remove-button {
    background-color: white;
    color: green;
    border: 1px solid green;
    border-radius: 30px;
    display: flex;
    font-size: 16px;
    cursor: pointer;
    padding: 6px 10px;
    transition: var(--tran2);
    width: 50px;
}


/* Responsive adjustments */
@media (max-width: 768px) {
    .popup-content {
        width: 80%;
        padding: 15px;
    }

    .popup-content h2 {
        font-size: 20px;
    }

    .Wefix-contact-image {
        width: 100%;
        height: auto;
    }

    .popup-row {
        flex-direction: column;
        gap: 10px;
    }

    .wefixemission-message {
        font-size: 12px;
    }

    .Wefix-img33 img {
        max-width: 100%;
        height: auto;
    }

    .wefix .remove-button {
        font-size: 14px;
        width: auto;
    }

    .wefix-pop-container h2 {
        margin-top: 4px;
    }

    .wefixspeedometer-container {
        border-right: none;
        border-bottom: 1px solid #ccc;
        padding: 10px 0;
        width: 100%;
    }
}