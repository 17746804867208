.header-form {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  font-family: "Quicksand";
  margin-bottom: 20px;
  padding: 20px;
  background-color: #c6e7df;
  border-radius: 8px;
}

.header-input,
.header-input-stay,
.header-input-dropdown,
.header-form select {
  padding: 10px;
  border-radius: 8px;
  border: none;
  background-color: white;
  width: 180px; 
  font-size: 14px;
}

.header-events,
.user-info,
.header-dropdowns {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
}

.header-events p,
.header-dropdowns p {
  font-size: 14px;
  font-weight: 600;
}

.stay-duration-box {
  display: flex;
  align-items: center;
  gap: 10px;
}

.stay-duration-label {
  display: flex;
  flex-direction: column;
}


.stop-label {
  font-size: 14px;
  color: #333;
  margin-top: -15px;
}

.start-label {
  font-weight: bold;
  color: #437298;
  font-size: 16px;
}

.end-label {
  font-weight: bold;
  color: #dc3545;
  font-size: 16px;
}

.mid-label {
  font-style: italic;
  color: #555;
}

.timeline {
  font-family: "Quicksand";
  position: relative;
  display: flex;
  margin-left: -5px;
  margin-bottom: -30px;
  flex-direction: column;
  gap: 30px;
}

.timeline::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #cccccc;
  transform: translateX(-50%);
}

.timeline-stop {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  gap: 15px;

  width: 100%;
}

.stop-label {
  flex: 0 0 46%;
  text-align: right;
  font-weight: bold;
  margin-right: 85px;
}

.circle {
  width: 25px;
  height: 25px;
  background-color: #ffffff;
  border: 4px solid #437298;

  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}

.dropdown-container {
  position: relative;
  width: 50%;
  display: flex;
  justify-content: center;

  margin-top: 20px;
  gap: 40px;
  margin-left: 31%;
}

.dropdown-container select {
  width: 100px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-left: 50px;
  font-size: 14px;
}

.dropdown-container select:last-of-type {
  width: 110px;
  margin-left: -60px;
}

.add-stop-container {
  position: relative;
  width: 100%;

  display: flex;
  justify-content: center;
}

.circle {
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border: 4px solid #437298;
  border-radius: 50%;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}

.start-circle {
  border-color: #437298;
}

.end-circle {
  margin-top: 5px;
  border-color: #dc3545;
}

.mid-circle {
  width: 20px;
  height: 20px;
  border-color: #525252;
}

.add-stop {
  position: absolute;
  margin-top: -55px;
  font-size: 1em;
  cursor: pointer;
  background-color: #676767;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stop-controls {
  display: flex;
  gap: 10px;
  margin-top: -5px;
  align-items: center;
  justify-content: center;
}

.stop-controls input {
  font-size: 14px;
  border: 1px solid grey;
  padding: 5px 10px;
  background-color: #f4f4f4;
  border-radius: 6px;
  width: 240px;
}

.carpool-input {
  position: absolute;
 right: 120px;
 padding: 2px 3px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.remove-stop {
  font-size: 1em;
  cursor: pointer;
  display: flex;
  margin-top: -1px;
  background-color: #676767;
  color: white;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.remove-stop:hover {
  background-color: #437298;
}

.new-add-info h2 {
  font-family: "Quicksand";
  text-align: center;
  margin-bottom: 35px;
  font-size: 16px;
}

.new-add-info h3 {
  font-family: "Quicksand";
  text-align: center;
  margin-bottom: 20px;
  margin-top: 60px;
  font-size: 14px;
}

.info-save-button {
  display: block;
  margin: 10px auto;
  padding: 10px 20px;
  width: 150px;
  background-color: #464d63;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.info-save-button:hover {
  background-color: #024671;
}

.loading-car {
  height: 100px;
  margin-bottom: 15px;
  width: auto;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: rgba(255, 255, 255, 0.7); 
  backdrop-filter: blur(1px);  */
  z-index: 9999; 
  opacity: 1; 
  visibility: visible; /* Ensures fade-out hides the element */
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
}

.loading-overlay.hidden {
  opacity: 0; 
  visibility: hidden; /* Makes the overlay non-interactive after fade-out */

  pointer-events: none; 
}

.loading-text {
  color: rgb(57, 57, 57);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 600px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-size: 34px;
  font-family: "Oswald";
  border: 2px grey dotted;
  text-transform: uppercase;
  padding: 20px;
  background: white; 
  border-radius: 12px;
  font-weight: 600;
  
  
}

.popup2-style{
  height: 300px;
  width: auto;
}

.loading-text1 span{
  font-size: 18px;
  text-transform: uppercase;
}



.new-add-info {
  transition: filter 0.5s ease-in-out; 
}


.new-add-info.blurred {
  filter: blur(2px); 
  pointer-events: none; 
  user-select: none; 
}



@media (max-width: 768px) {

  .header-form {
    flex-direction: row; /* Keep the form fields in a row */
    gap: 20px;
    align-items: flex-start;
  }

  .header-events,
  .header-dropdowns {
    flex-direction: row; /* Keep the form fields in a row */
    flex-wrap: wrap; /* Allow wrapping */
    gap: 20px;
    width: 100%;
  }

  .user-info {
    flex-direction: row; /* Keep the form fields in a row */
    flex-wrap: wrap; /* Allow wrapping */
    gap: 20px;
    width: 100%;
  }

  .user-info label,
  .header-dropdowns label,
  .stay-duration-box label {
    width: calc(50% - 10px); /* Two fields per row with gap */
    text-align: left;
  }

  /* Increase the width of the "Stay at Hotel" dropdown */
  .stay-duration-box label {
    width: 100%; /* Full width for "Stay at Hotel" */
  }

  .header-input,
  .header-form select {
    width: 100%; /* Full width for inputs */
  }
  /* timeline */

  

  .stop-label {
    font-size: 12px;
  }

  .start-label {
    font-size: 14px;
  }

  .end-label {
    font-size: 14px;
  }

  .mid-label {
    font-style: italic;
    color: #555;
    margin-top: 3px;
  }

  .timeline {
    margin-left: -40px;
    margin-bottom: -30px;
    gap: 25px;
  }

  .timeline::before {
    left: 50%;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: #cccccc;
    transform: translateX(-50%);
  }

  .timeline-stop {
    gap: 25px;

    width: 100%;
  }

  .stop-label {
    flex: 0 0 40%;
    margin-right: 50px;
  }

  .stop-controls {
    display: flex;
    gap: 8px;
    margin-top: -5px;
    align-items: center;
    justify-content: center;
  }

  .stop-controls input {
    font-size: 12px;
    border: 1px solid grey;
    padding: 3px 7px;
    background-color: #f4f4f4;
    border-radius: 6px;
    margin-top: 4px;
    width: 100%;
  }

  .dropdown-container {
    position: relative;
    width: 50%;
    display: flex;
    justify-content: center;

    margin-top: 20px;
    gap: 10px;
    margin-left: 34%;
  }

  .dropdown-container select {
    width: 70px;
    padding: 3px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-left: 60px;
    font-size: 12px;
  }

  .dropdown-container select:last-of-type {
    width: 60px;
    margin-left: -20px;
  }

  .info-save-button {
  margin-left: 70px;
  }

  .loading-car {
    height: 80px; 
    margin-bottom: 10px;
  }

  .loading-text {
    width: 90%; 
    font-size: 20px; 
    height: auto; 
    padding: 15px; 
  }

  .loading-text1 span {
    font-size: 12px; 
  }

  .popup2-style {
    height: auto; 
    width: 50%;
  }

  .loading-overlay {
    padding: 10px; 
  }

  .new-add-info h3 {
   
    font-size: 12px;
    width: 80%;
    margin-left: 40px;
  }

  .new-add-info h2 {
   
    font-size: 14px;
    width: 80%;
    margin-left: 40px;
  }
  
}