/* General Styles */
.wefixgrid-container {
  font-family: "Quicksand";
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
  margin: 25px auto;
  gap: 20px;
}

/* Left Section: Form */
.wefix-container {
  width: 50%;

  padding: 20px;
  border-radius: 8px;

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.wefix-logo {
  display: flex;
  padding: 5px;
  margin-bottom: 40px;
  text-align: left;
  justify-content: space-between;
}

.wefix-logo img {
  height: 50px;
  width: auto;
}

.wefix-container label {
  display: block;
  font-size: 16px;
  color: #002b54;
  font-weight: bold;
  margin-bottom: 5px;
  width: 100%;
}

.wefix-container input {
  padding: 5px 15px;
  color: #666;
  width: 100%;
  margin-bottom: 8px;
  border-radius: 8px;
  border: 1px solid #002b5444;
}

.ac-details h2 {
  color: #002b54aa;
  font-family: "Anton";
  font-size: 26px;
}

.form-groups {
  margin-bottom: 20px;
}

/* Arrange AC fields: capacity and quantity on separate rows */
.form-group1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
}

.input-with-unit {
  display: flex;
  align-items: center;
  gap: 5px;
}

.remove-button {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
/* Add AC and Remove AC buttons in one row */
.btn-group {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
}


.remove-ac-button {
  width: 10%;
  background-color: #4d4d4d;
  color: rgb(252, 246, 246);
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-group2,
.form-group3 {
  margin-bottom: 20px;
}

.wefix-Button {
  text-align: center;
}

.wefix-Button {
  width: 40%;
  background-color: #002b54;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.wefix-Button:hover {
  background-color: #e08d35;
}

.wefix-container input[type="text"],
.wefix-container input[type="email"],
.wefix-container input[type="number"] {
  width: 80%;
  padding: 5px 14px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.select-wrapper {
  position: relative;
  width: 200px;
}

.select-wrapper select {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  color: #333;
  font-size: 16px;
  cursor: pointer;
}

.ac-details {
  margin: 40px 0;
  font-family: "Anton";

  font-weight: bold;
  color: #002b54;
}

.ac-details h2 {
  font-size: 26px;
}

.form-groups {
  margin-bottom: 20px;
}

/* Arrange AC fields: capacity and quantity on separate rows */
.form-group1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
}

.input-with-unit {
  display: flex;
  align-items: center;
  gap: 5px;
}

.input-with-unit label {
  width: 100%;
  margin-bottom: 5px; /* Stack label above the input */
  font-weight: bold;
}

.input-with-unit input[type="number"] {
  width: 50%; /* Full width for this input */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.remove-button {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
/* Add AC and Remove AC buttons in one row */
.btn-group {
  display: flex;
  gap: 10px;
  align-items: center;

  margin-bottom: 20px;
}

.add-button {
  width: 60%;
  background-color: #002b54cc;
  color: white;
  padding: 8px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-button:hover {
  background-color: #424242;
}

.remove-ac-button {
  width: 10%;
  border: 1px #ddd solid;
  background-color: white;
  font-size: 24px;
  color: rgb(9, 9, 9);
  padding: 0px;
  border-radius: 4px;
  cursor: pointer;
}

.remove-ac-button:hover {
  background-color: rgb(131, 131, 131);
}

.form-group2,
.form-group3 {
  margin-bottom: 20px;
}

.wefix-Button {
  text-align: center;
}

.wefix-Button {
  width: 40%;
  background-color: #002b54;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.wefix-Button:hover {
  background-color: #355471;
}

.wefix-Button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Right Section: Images and Emission Details */
.ac-images {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0px;
  height: 100%;
  justify-content: space-between;
}

.ac-main-image {
  display: flex;
  justify-content: center;
  padding: 20px;

  background-color: #fff;
  border-radius: 8px;
}

.ac-main-image img {
  width: 450px;
  height: auto;
}

.ac-emissions {
  display: flex;
  background-color: #fff;
  padding: 15px;
  border-top: 1px dashed rgb(109, 109, 109);
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.wefix-emission-details {
  display: flex;
  margin-left: -10px;
  margin-top: 0px;
  gap: 15px;
  flex-direction: column;
}

.emission-meter11 h2 {
  font-size: 30px;
  font-family: "anton";

  margin-top: 0;
}

.emission-meter12 h2 {
  font-size: 32px;

  font-weight: bold;
  color: #014a63;
  margin-left: 50px;
  margin-top: 0;
}

.wefix-emission-level {
  text-align: left;
  font-size: 14px;
  margin-top: -95px;
  width: 100%;
}

@media (max-width: 1020px) {
  .wefixgrid-container {
    width: 95%;
  }
}
/* Responsive Styles */
@media (max-width: 768px) {
  .wefixgrid-container {
    flex-direction: column;
    width: 95%;
    gap: 10px;
  }

  .wefix-container {
    width: 100%;
    padding: 15px;
  }

  .wefix-logo {
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-left: 0;
    margin-bottom: 20px;
    text-align: center;
  }

  .form-row {
    flex-direction: column;
    gap: 10px;
    margin-left: 0;
    margin-bottom: 15px;
  }
  
  .select-wrapper {
    width: 100%;
  }

  .wefix-container input[type="text"],
  .wefix-container input[type="email"],
  .wefix-container input[type="number"],
  .select-wrapper select {
    width: 100%;
  }

  .btn-group {
    flex-direction: column;
    gap: 10px;
    align-items: stretch;
  }

  .add-button,
  .remove-ac-button,
  .wefix-Button {
    width: 100%;
  }

  .ac-details h2 {
    font-size: 22px;
    text-align: center;
  }

  .ac-images {
    width: 100%;
  }

  .ac-main-image img {
    width: 100%;
    height: auto;
  }

  .ac-emissions {
    flex-direction: column;
    text-align: center;
  }

  .wefix-emission-details {
    margin-top: -110px;
    margin-left: -20px;
    align-items: center;
    gap: 10px;
  }

  .emission-meter12 h2 {
    font-size: 28px;

    text-align: center;
  }

  .wefix-emission-level {
    font-size: 14px;
    margin-top: 0;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .wefix-logo img {
    height: 40px;
  }

  .ac-details h2 {
    font-size: 20px;
  }

  .wefix-Button {
    font-size: 14px;
    padding: 8px 15px;
  }

  .form-group1,
  .form-group2,
  .form-group3 {
    margin-bottom: 10px;
  }

  .input-with-unit input[type="number"] {
    width: 100%;
  }

  .ac-main-image img {
    width: 100%;
  }

  .emission-meter12 h2 {
    font-size: 24px;
  }

  .wefix-emission-level {
    font-size: 10px;
  }
}
