.new-event-page {
  background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  height: 100%;
}

.firstform {
  display: flex;
  flex-direction: column;
  background-color: var(--aqua);
  padding: 2.5rem 3rem 3rem 3rem;
  border: 1px solid var(--darkbluesemi);
  border-top: none;
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

.firstform h2 {
  font-family: "Raleway", sans-serif;
  font-size: 1.3em;
  color: var(--greyLight-3)!important;
}

.firstform label {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--darkblue);
  font-size: 1.3rem;
}

.firstform input,
.firstform select {
  border-color: var(--darkbluesemi);
  border-radius: 5px;
  color: #888;
  height: 35px;
  padding: 0 15px;
}

.save-button,
.next-button {
  padding: 0px 20px;
  width: 160px;
  border-radius: 3px;
  border: 1px solid var(--darkblue);
  color: var(--darkblue);
  transition: var(--tran2);
}


.next-button {
  margin-left: 10px;
}


.save-button:hover,
.next-button:hover {
  color: #ffffff !important;
  background-color: var(--darkblue);
}

.checkboxGroup {
  margin-bottom: 10px;
}

.event-check {
  font-size: 13px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  cursor: pointer;
  position: relative;
  width: 18px;
  height: 18px;
  -webkit-tap-highlight-color: transparent;
  transform: translate3d(0, 0, 0);
}

.event-check svg {
  position: relative;
  z-index: 1;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #c8ccd4;
  stroke-width: 1;
  transform: translate3d(0, 0, 0);
  transition: all 0.2s ease;
}

.event-check svg path {
  stroke-dasharray: 60;
  stroke-dashoffset: 0;
}

.event-check svg polyline {
  stroke-dasharray: 22;
  stroke-dashoffset: 66;
}

.event-check:hover svg {
  stroke: var(--darkblue);
}

input[type="checkbox"]:checked+.event-check svg {
  stroke: var(--darkblue);
}

input[type="checkbox"]:checked+.event-check svg polyline {
  stroke-dashoffset: 42;
  transition: all 0.2s linear;
  transition-delay: 0.15s;
}

.radio-label {
  display: flex;
  position: relative;
}

.form-check-input {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 1px;
  height: 1px;
  opacity: 0;
  z-index: 1;
}

.radio-text {
  margin-left: 10px;
  padding: 0px;
}

.radio-design {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: linear-gradient(to right bottom, var(--darkblue), hsl(225, 51%, 61%));
  position: relative;
  top: 4px
}

.radio-design::before {
  content: '';
  display: inline-block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: hsl(0, 0%, 100%);
  transform: scale(1.1);
  transition: .3s;
}

.form-check-input:checked+.radio-design::before {
  transform: scale(0);
}