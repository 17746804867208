/* OrganizerData.css */

/* form {
    border: none;
    box-shadow: none;
}
input[type="text"]:focus, input[type="text"]:hover {
    outline: none;
    box-shadow: none; 
} */

.table-responsive {
  overflow-x: auto;
}


.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.form-group {
  flex: 1;
  margin-right: 20px;
}

.form-group:last-child {
  margin-right: 0;
}

/* label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #333;
} */

/* input[type="text"], select {
    width: 100%;
    padding: 10px; 
    border-radius: 5px; 
    border: 1px solid #ccc; 
    font-size: 12px; 
    box-sizing: border-box; 
} */

.organizer-table {
  border: 1px solid #eee;
}

.organizer-table th {
  background-color: var(--darkblue);
  color: var(--white);
  font-size: 15px;
  font-weight: 300;
  border-right: 1px solid #fff;

  &:first-child {
    border-top-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-right: none
  }
}

.organizer-table td {
  color: var(--darkblue);
  font-size: 13px;
}

.organizerForm {
  display: flex;
  flex-direction: column;
  background-color: var(--aqua);
}

.organizerForm label {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--darkblue);
  font-size: 1.3rem;
  width: 100%;
}

.organizerForm input,
.organizerForm select {
  border: 1px solid var(--darkbluesemi);
  font-size: 1.3rem;
  border-radius: 5px;
  background-color: #f8f9fa;
  color: #888;
  width: 100%;
  height: 35px;
  padding: 0 15px;
  margin: 0;
  
  &:focus {
    outline: none;
  }
}

.organizerForm h3 {
  font-family: "Raleway", sans-serif;
  font-size: 1.2em;
  color: var(--greyLight-3)!important;
}

.Organizer_Map_Iocn {
  position: absolute;
  right: 3rem;
  top: 1rem;
  color: var(--darkblue);
}

/*
.submit-button {
  background-color: #00695c;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.submit-button:hover {
  background-color: #004d40;
}

.event-name-container {
  margin-top: -50px;
}

.selected-meeting-name {
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: bold;
}

 .meeting-list {
    max-height: 200px; 
    overflow-y: auto;  
    position: relative;
  }
  
  .meeting-list::-webkit-scrollbar {
    width: 8px; 
  }
  
  .meeting-list::-webkit-scrollbar-track {
    background: transparent;
    border: none; 
  }
  
  .meeting-list::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px; 
  }

.meeting-list .table-container {
    display: block; 
    overflow-y: auto; 
    max-height: 160px; 
  }
  
   */

.go-button {
  background-color: var(--white);
  color: var(--darkblue);
  padding: 5px 10px;
  border: 1px solid var(--darkblue);
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.3rem;
  transition: all 0.3s ease;
}

.go-button:hover {
  background-color: var(--darkblue);
  color: var(--white);
}