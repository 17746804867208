.dashboard {
  display: flex;
}

.main-content {
  padding: 1.5rem 1rem;
  border-radius: 3rem 0 0 3rem;
  position: relative; 
  z-index: 1; 
  background-image: linear-gradient(135deg, #F0F8FF 0%, #c3cfe2 100%);
}

.main-content hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 2px solid #ffffff;
  margin: 0;
  padding: 0;
}

.main-content-header {
  text-transform: uppercase;
  font-family: 'raleway';
  color: var(--darkblue);
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 2px;
}

.value-sub {
  font-size: 12px;
  font-weight: normal;
}

/* Responsive Styles */
@media (min-width: 500px) {
  .my-events-navbar {
    padding: 50px;
    height: 90px;
  }

  .add-event-button {
    width: 20%;
    margin: 10px 0;
  }

  .select-event-button {
    width: 100%;
  }

  .dashboard {
    flex-direction: row;
  }
}

@media (max-width: 768px) {
  .add-event-button {
    width: 20%;
    font-size: 10px;
    margin: 10px 0;
  }

  .select-event-button {
    font-size: 10px;
    width: 100%;
  }

  .value-sub {
    font-size: 8px;
    color: #666;
    text-align: center;
  }

  .value-sub p {
    margin: 0;
  }

  .table-cell {
    font-size: 10px;
  }

  .table-value {
    margin: 8px 0;
  }

  .chartsRow {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .dashboard-actions {
    flex-direction: column;
  }

  .value-sub {
    font-size: 8px;
    text-align: center;
  }

  .value-sub p {
    margin: 0;
  }

  .add-event-button,
  .select-event-button {
    padding: 8px;
    font-size: 14px;
  }
}

.dashboard-darkbox {
  border-radius: 1.5rem;
  color: hsl(0, 0%, 100%);
  background-color: var(--greyLight-3);
  box-shadow: var(--shadow);
  height: 145px;
}

.dashboard-card-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 75px;
  border-bottom: 1px solid #bfc4d3;
}

.dashboard-card-header-title {
  padding: 20px 25px;
  flex: 80%;
}

.dashboard-darkbox .dashboard-card-header-title h3 {
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  line-height: 1.25;
  color: #ffffffee;
}

.DashbordNoBox .dashboard-card-header-title h3 {
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  line-height: 1.25;
  color: var(--darkbluesemi2);
}

.dashboard-card-header-image {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.dashboard-card-header-image .dashboard-image {
  position: relative;
  left: -20px;
  width: 45px;
  height: 45px;
}

.DashbordNoBox {
  /* border: 1px solid #fff; */
  /* background-color: #e3f2f5; */
  border-radius: 1.5rem;
  color: var(--darkblue);
  background-color: var(--aqua);
  box-shadow: var(--shadow);
  height: 145px;
}

.dashboard-value {
  padding: 10px 25px;
}

.dashboard-value p {
  font-size: 22px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}

.icon-shadow {
  color: var(--darkblue);
  padding: 10px;
  border-radius: 50%;
  border: var(--darkblue) solid 1px;
  height: 20px;
  width: 20px;
}

.chart-modal {
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--tran2);
}

.chart-modal-container {
  display: flex;
  pointer-events: none;
 
  justify-content: center; /* Horizontally centers the container */
  align-items: center;
  width: 850px; /* Increased width */
  height: 650px;
  border-radius: 10px;
  overflow: hidden;
  position: absolute;
  opacity: 0;
  margin-bottom: 35px;
  transition-duration: 0.3s;
  transform: translateY(100px) scale(0.4);
  transition: transform 300ms;
}

.chart-modal-content {
  width: 850px; /* Increased width */
  height: 650px;
  display: flex;
  z-index: 11;
  justify-content: center;
  background-color: var(--aqua);
  border-radius: 8px;
  margin-top: 70px;
  padding-right: 20px;
  
  padding: 0rem ;
  color: var(--darkblue);
}

.is-open {
  height: 100vh;
  width: 100%;

  .chart-modal-container {
    opacity: 1;
    transition-duration: 0.6s;
    pointer-events: auto;
    transform: translateY(0) scale(1);
  }
}

.close {
  position: absolute;
  right: 25px;
    
  color: #ffffff;
  font-size: 35px;
  font-family: "raleway", sans-serif;
  cursor: pointer;
  transition: var(--tran1);

  &:hover {
    font-weight: 500;
    color: #000000f0;
  }
}

@media (max-width: 768px) {
  .chart-modal-container {
    
    width: 90%;
    height: auto; 
    transform: translateY(50px) scale(0.8); 
  }

  .chart-modal-content {
    
    width: 90%; 
    height: auto;
  }
}

@media (max-width: 480px) {
  .chart-modal-container {
    width: 95%; 
    height: auto; 
    transform: translateY(20px) scale(0.7);
  }

  .chart-modal-content {
    display: flex;
    flex-direction: column; 
    width: 95%; 
    height: auto; 
    padding: 10px; 
  }

  .close {
    font-size: 25px;
    color: black;
    right: 15px;
  }
}
