
.centerdiv{
    height:40vh;
    display: flex;
    justify-content: center;
    align-items: center;
} 

.centerdiv a {
    height: 100px;
    width: 100px;
    background-color: #f5f6fa;
    border-radius: 50%;
    margin: 40px;
    display: flex;
    justify-content: center;
    text-decoration: none;
    align-items: center;
    box-shadow: 1px 4px 2px 2px #dcdde1;
    position: relative;
    overflow: hidden;
}
.centerdiv a i{
    transition: all 0.3s linear;
}

.centerdiv a:hover .fa{
    transform:scale(1.5);
    color: #f5f6fa;
}

.centerdiv a:before{
    content: "";
    width: 120%;
    height: 120%;
    position: absolute;
    top:90%;
    left: -50%;
    background-color: #136b88;
    transform: rotate(60deg);
} 

.centerdiv a:hover:before{
    animation: socialicons 0.8s 1;
    animation-fill-mode: forwards;
}

@keyframes socialicons{

    0% {top:90%; left: -50%;}
    50% { top: -60%; left: -10%;}
    100% { top: -10%; left: -10%;}
}

.fa-instagram{
    color:#e84393;
}
.fa-twitter{
    color:#67b9e6;
}

.fa-linkedin{
    color:#0097e6;
}
.fa-facebook{
    color:#311f7d;
}


.followtwonav-left {
    flex: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: var(--fs1);
  }

  .followtwonav-left img{
    margin-top: 60px;
    width: 100px;
    height: 50px;
  }

  .text-center {
    margin: 30px 0; 
    margin-top: 40px;
  }
  
  .core {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  
  .imp-text {
    flex: 40%;
  padding: 0% 5% 10% 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  }
  .imp-text h1{
    text-transform: uppercase;
  font-family: 'Oswald';
  color: #36454F;
  font-size: 48px;
  font-weight: 600;
  }

  .imp-text h3 {
    font-size: 20px;
    margin-top: 10px;
  }

  .followtwonav{
    display: flex;
  }
  .pic img{
    width: 800px;
    height: 100%;
    object-fit: contain;
    margin-top: 0px;
  }
 
  .eco{
    display: flex;
    flex-direction: column;
    justify-content: center;
    scroll-snap-align: start;
    height: 100vh;
    width: 100%;
  }

  .eco1 {
    background-image: linear-gradient(left,
        var(--skyblue),
        var(--skyblue) 70%,
        transparent 70%,
  
        transparent 100%);
    background-image: -webkit-linear-gradient(left,
        var(--skyblue),
        var(--skyblue) 70%,
        transparent 70%,
        transparent 100%);
  }



@media (max-width: 768px) {

    .eco1 {
        background-image: linear-gradient(left,
            var(--skyblue),
            var(--skyblue) 100%,
            transparent 100%,
      
            transparent 100%);
        background-image: -webkit-linear-gradient(left,
            var(--skyblue),
            var(--skyblue) 100%,
            transparent 100%,
            transparent 100%);
      }
    

    .core {
      flex-direction: column; 
      align-items: center;
    }
    
  
    .pic img {
        margin-top: 40px;
      width: 100%; 
      margin-bottom: 20px; 
    }
  
    .centerdiv {
      display: flex;
      flex-direction: row; 
      align-items: center;
      justify-content: center;
      gap: 20px;
      margin-top: -100px;
    }
  
    .centerdiv a {
      
      margin: 10px 0; 
      width: 50px;
      height: 50px;
    }

    .centerdiv a i{
        justify-content: center;
        align-items: center;
        
    }

  
    
    .imp-text h1{
        font-size: 24px;

      
    }

    .imp-text h3{
      font-size: 14px;
    
      width: 100%;
    
    }

    .text-center {
      margin: 5px 0;
    }
  }