/* DoughnutChart.css */
.chart-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  background-color: var(--aqua);
  border-radius: 1rem;
  box-shadow: var(--shadow);
  height: 450px;
}

.chart-header {
  text-align: center;
  width: 100%;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #bfc4d3;
}

.chart-header h3 {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  line-height: 1.25;
  color: var(--darkbluesemi2);
  font-weight: 600;
}

.chart-data {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 15px;
  height: 380px;
}

#myChart3 {
  padding: 0 15px 30px 15px;
}