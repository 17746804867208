.shuttleForm {
  display: flex;
  flex-direction: column;
  background-color: var(--aqua);
}

.shuttleForm label {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--darkblue);
  font-size: 1.3rem;
  width: 100%;
}

.shuttleForm input,
.shuttleForm select {
  border: 1px solid var(--darkbluesemi);
  border-radius: 5px;
  font-size: 1.3rem;
  background-color: #f8f9fa;
  color: #888;
  width: 100%;
  height: 35px;
  padding: 0 15px;
  margin: 0;
  
  &:focus {
    outline: none;
  }
}

.shuttle-table {
  border: 1px solid #eee;
}

.shuttle-table th {
  background-color: var(--darkblue);
  color: var(--white);
  font-size: 15px;
  font-weight: 300;
  border-right: 1px solid #fff;

  &:first-child {
    border-top-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-right: none
  }
}

.shuttle-table td {
  color: var(--darkblue);
  font-size: 13px;
}


/* .shuttle-transport-table-wrapper-one {
  width: 90%;
  overflow-x: auto;
  margin-bottom: 20px;
  margin-left: 10px;
}

.shuttle-transport-table-one {
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
}

.shuttle-transport-header-one,
.shuttle-transport-row-one {
  display: flex;
  justify-content: space-between;
  background-color: #f8f9fa;
  font-weight: bold;
}

.shuttle-transport-cell-one {
  flex: 1;
  padding: 8px;
  border: 1px solid #dee2e6;
}

.shuttle-transport-table-wrapper-two {
  width: 90%;
  overflow-x: auto;
  margin-bottom: 20px;
  margin-left: 10px;
  max-height: 300px;
  overflow-y: auto;
}

.shuttle-transport-table-two {
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
}

.shuttle-transport-table-two th {
  background-color: #007272;
  color: white;
  padding: 10px;
  text-align: left;
}

.shuttle-transport-table-two td {
  padding: 8px;
  border: 1px solid #dee2e6;
  text-align: left;
}

.shuttle-transport-header-two th {
  background-color: #f8f9fa;
  color: #333;
}

.shuttle-transport-cell-two {
  flex: 1;
  padding: 8px;
  border: 1px solid #dee2e6;
}

.vehicle-cell,
.fuel-cell,
.distance-cell {
  flex: 1;
  text-align: center;
}

.vehicle-dropdown,
.fuel-dropdown {
  width: 100%;
  height: 40px;
  padding: 5px;
}

.distance-input-container {
  display: flex;
  align-items: center;
}

.distance-input-container input {
  width: 150px;
  height: 40px;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #dee2e6;
}

.distance-input-container select {
  width: 100px;
  height: 40px;
}

.shuttle-btn-save {
  background-color: #007272;
  border-color: #007272;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
}

.shuttle-btn-delete {
  background-color: #ff4d4d;
  border-color: #ff4d4d;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
}

.shuttle-btn-save:hover,
.shuttle-btn-delete:hover {
  background-color: #005f5f;
  border-color: #005f5f;
}

.shuttle-empty-row {
  text-align: center;
}

.shuttle-transport-save-button {
  float: right;
  margin-top: -12px;
  margin-right: 100px;
  margin-bottom: 10px;
  padding: 10px 20px;
  background-color: #007272;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.shuttle-transport-save-button:hover {
  background-color: #005f5f;
}

.shuttle-transport-save-container {
  text-align: right;
  margin-top: 10px;
} */
