@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;700&family=Montserrat:wght@300;500&display=swap');

.sort-delete-container {
  font-weight: 400;
  color: var(--darkblue);
  font-size: 16px;
  float: left;
}

.deleteBtn,
.ShortBY {
  font-size: 14px;
  font-weight: 400;
  background: #ffffff;
  color: #000000;
  border: #ced4da solid 1px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
}

.deleteBtn:hover,
.ShortBY:hover {
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  color: #333333;
  /* Dark grey color for font on hover */
}

.deleteBtn {
  font-size: 11px;
  /* Decreased font size */
  font-weight: 400;
  background: #ffffff;
  color: #5a5959;
  /* Dark grey color for font */
  box-shadow: none;
  border: none;
  padding: 0px;
  padding-left: 5px;
}

.deleteBtn:hover {
  box-shadow: none;
  border: none;
}

.deleteBtn .fa-trash-can {
  color: #5a5959;
  /* Dark grey color for the delete icon */
  /* margin-right: 2px; */
  font-size: 16px;
}

/* Existing CSS remains unchanged */

.deleteBtn-container {
  position: relative;
  display: inline-block;
}

.deleteBtn-container .fa {
  font-size: 20px;
  cursor: pointer;
  margin-left: 28px;
}

.deleteBtn {
  font-size: 11px;
  font-weight: 400;
  background: #ffffff;
  color: #5a5959;
  /* Dark grey color for font */
  box-shadow: none;
  border: none;
  padding: 0px;
  padding-left: 5px;
}

.deleteBtn:hover {
  box-shadow: none;
  border: none;
}

.deleteBtn .fa-trash-can {
  color: #5a5959;
  /* Dark grey color for the delete icon */
  font-size: 14px;
}

.deleteBtn-container:hover .delete-tooltip {
  visibility: visible;
  opacity: 1;
}

.delete-tooltip {
  visibility: hidden;
  width: 60px;
  background-color: #5a5959;
  color: #f3f0f0;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  /* Position above the button */
  left: 50%;
  margin-left: -30px;
  /* Adjust based on tooltip width */
  opacity: 0;
  transition: opacity 0.3s;
}

.delete-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  /* Arrow at the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #5a5959 transparent transparent transparent;
}

.MeetingBoxWrp {
  padding: 15px;
  border-top: #ced4da solid 1px;
  margin-bottom: 0px;
}

.mrgn4px {
  margin-left: 4px;
}

.meeting-summary {
  border-radius: 15px;
  box-shadow: var(--shadow);
}

.show-summary {
  padding: 10px 20px;
  color: var(--greyLight-3);
  font-weight: 500;
  font-size: 1.8rem;
}

.meeting-modal .modal-content{
  padding: 0.6rem;
  border: none;
  border-radius: 1.2rem;
  background-color: var(--aqua);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.575);
}

.meeting-modal .modal-header {
  padding: 10px 20px;
  background-color: var(--greyLight-3);
  border-radius: 0.6rem 0.6rem 0 0;
  color: #fff;
}

.meeting-modal th {
  background-color: var(--darkblue);
  &:first-child {
    border-top-left-radius: 5px;
  }
  &:last-child {
    border-top-right-radius: 5px;
  }
}

.Meetingtable_Scroll_Wrp {
  margin: 8px 0px 0px 0px;
  overflow-x: hidden;
  padding: 0;
}

.Meetingtable_Scroll_Wrp table {
  border-radius: 1rem;
}

.Meetingtable_Scroll_Wrp th {
  background-color: var(--darkblue);
  color: var(--white);
  font-size: 15px;
  font-weight: 300;
  border-right: 1px solid #fff;

  &:first-child {
    border-top-left-radius: 1rem;
  }

  &:last-child {
    border-top-right-radius: 1rem;
    border-right: none;
  }
}

.Meetingtable_Scroll_Wrp td {
  color: var(--darkblue);
  font-size: 13px;
}

.custom-button {
  background-color: var(--darkblue);
  color: white;
  padding: 3px 12px!important;
  border: 1px solid var(--darkblue);
}

.custom-button:hover {
  background-color: var(--aqua);
  color: var(--darkblue);
}

.view-meeting {
  border: 1px solid var(--darkblue);
  border-radius: 20px;
  padding: 3px 12px!important;
}

.toast-notification {
  position: fixed;
  bottom: 20px;
  /* Adjust this to move it up or down */
  left: 50%;
  transform: translateX(-50%);
  background-color: #323232;
  color: #fff;
  padding: 12px 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  z-index: 1000;
  opacity: 0.9;
  animation: fadeInOut 3s ease-in-out;
}

.Meetingtable_Scroll_Wrp {
  width: 100%;
  overflow-x: auto;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0.9;
  }

  90% {
    opacity: 0.9;
  }

  100% {
    opacity: 0;
  }
}