.attendee-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  pointer-events: auto;
}

.attendee-modal .attendee-modal-dialog {
  background-color: var(--aqua);
  border-radius: 1.2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.575);
  transition: 0.3s;
  width: 590px;
  max-width: 90%;
  padding: 0.6rem;
  outline: none;
  position: relative;
}

.attendee-modal-content {
  display: flex;
  flex-direction: column;
}

/* Header section */
.attendee-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: var(--darkblue);
  border-radius: 0.6rem 0.6rem 0 0;
}

#alert-title {
  font-size: 15px;
  color: var(--white);
}

/* Close button */
.attendee-close-button {
  position: absolute;
  top: -15px;
  right: 15px;
  border: none;
  font-size: 24px;
  color: var(--white);
  cursor: pointer;
}

/* Modal body */
.attendee-modal-body {
  padding: 20px;
  margin-bottom: 15px;
}

/* Footer section with buttons */
.attendee-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 10px;
}

/* Confirm button */
.attendee-confirm-button {
  padding: 8px 16px;
  background-color: var(--darkblue);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.3s ease;
}



.attendee-confirm-button:hover {
  background-color: var(--darkteal);
  color: var(--white);
  transform: scale(1.025);
}

/* ---------------Animation---------------- */
.slit-in-vertical {
  -webkit-animation: slit-in-vertical 0.45s ease-out both;
  animation: slit-in-vertical 0.45s ease-out both;
}

@-webkit-keyframes slit-in-vertical {
  0% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
    transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }

  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
    transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
  }
}

@keyframes slit-in-vertical {
  0% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
    transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }

  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
    transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
  }
}

.for-flex{
  height: 12vh;
  display: flex;
}

.emission-info {
  text-align: center;
  margin-top: 20px;
}


.emission-info p{
  margin-left: 0px;
}

@media (max-width: 460px) {

  .attendee-modal .attendee-modal-dialog {
    width: 300px;
    height: 400px;
    max-width: 90%;
  }

  
  .for-flex {
    flex-direction: column;  
    align-items: center;    
    width: 100%;            
    margin-left: 0;        
  }

  .emission-info {
    font-size: 0.9rem; 
    text-align: center;
    
    margin-top: -80px; 
  }

  .emission-value {
    font-size: 14px;
  }

  .emission-level {
    font-size: 12px; 
  }

  .attendee-modal-header {
    padding: 10px;
  }

  #alert-title {
    font-size: 12px;
  }

  
  .attendee-modal-footer {
    padding: 10px;
    justify-content: center;
    display: flex;
    flex-direction: column;  
    align-items: center;
    margin-top: 110px; 
  }

  

  .attendee-close-button {
    font-size: 16px;
  }
}
