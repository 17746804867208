.ecoindex-sidebar {
  display: flex;
  width: 240px;
 font-size: 16px;
}

.ecoindex-sidebar.collapsed {
  width: 60px;
  height: 100%;
 
}

.sidebar {
  height: 100vh;
  width: 240px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--greyLight-3);
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
}

.sidebar.collapsed {
  width: 60px;
  height: 100%;
}

.sprout {
  display: flex;
  width: 100%;
  margin: 5rem 3rem;
}

.sprout.collapsed {
  height: 35px;
  margin: 2.5rem 1.5rem;
}

.sprout img {
  height: auto;
}

.profile {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  align-items: center;
  padding: 0rem 3rem 3rem 0rem;
}

.profile.collapsed {
  padding: 0 0.7rem 3rem 0;

}

.profile.collapsed .profile-img {
  width: 40px;
  height: 40px;
  margin-left: 0;
}

.profile-img {
  border-radius: 50%;
  width: 65px;
  height: 65px;
  background-size: cover;
}

.profile p {
  font-family: 'Rubik', sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: var(--white);
}

.sidebar-navigation {
  width: 100%;
}

.sidebar-navigation ul {
  font-style: normal;

  list-style-type: none;
  padding: 0;
  width: 100%;
}

.sidebar-navigation ul li {
  padding: 10px;
  width: 100%;
  
}

.sidebar-navigation ul li a {
  color: var(--aqua);
  font-size: 16px;
  display: block;
  width: 100%;
  height: 100%;
}

.sidebar-active,
.sidebar-navigation ul li:hover {
  color: var(--white);
  background-size: cover;
  overflow: visible;
}

.sidebar-navigation ul li a.sidebar-active,
.sidebar-navigation ul li a:hover {
  color: var(--white);
  text-decoration: none;
}

.sidebar-navigation ul li a .svg-inline--fa {
  margin-right: 10px;
}

.sidebar-navigation ul li a .icon-collapsed {
  width: 25px;
  height: 25px;
  margin-right: 0;
  margin-left: 3px;
}

.icon-collapsed {
  width: 20px;
  height: 20px;
  margin-left: -9px;
}

.logout1 {
  color: var(--aqua);
  margin-top: auto;
  margin-bottom: 10px;
  cursor: pointer;
  height: 100%;
}

.content {
  margin-top: 0px;
  margin-left: 180px;
  width: 100%;
  overflow-y: auto;
  height: 100vh;
  transition: margin-left 0.3s ease-in-out;
}