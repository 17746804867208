@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;700&family=Montserrat:wght@300;500&display=swap");

.summary-form h2 {
  font-family: "Raleway", sans-serif;
  font-size: 1.2em;
  color: var(--greyLight-3);
}

.update-attendee-header {
  font-family: "Raleway", sans-serif;
  font-size: 1.25em;
  color: var(--greyLight-3);
}

.summary-form label {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--darkblue);
  font-size: 1.3rem;
}

.summary-form input {
  box-shadow: inset -2px -2px 4px rgba(255, 255, 255, .7), inset 1px 1px 3px #c5d4e3, inset 2px 2px 6px #c5d4e3;
  padding: 15px 20px;
  border-radius: 1.5rem;
  text-align: right;
  background-color: #f0f2fb;
  font-size: 1.3rem;
}

.generate-button {
  color: var(--darkbluesemi2);
  border: 1px solid var(--darkblue);
  border-radius: 1rem;
  text-align: center;
  margin: 0;
  margin-right: 15px;
  padding: .5rem 1.2rem;
  font-size: 14px;

  &:hover {
    color: var(--darkblue);
  }
}

.summary-table {
  width: 100%;
  font-size: 12px;
  border-radius: 8px;
}

.summary-table td {
  color: var(--darkblue);
  font-size: 13px;
  border: 1px solid #ddd;
  border-collapse: collapse;
}

.summary-table th {
  background-color: var(--darkblue);
  color: var(--white);
  font-size: 15px;
  font-weight: 300;
  border-right: 1px solid #fff;

  &:first-child {
    border-top-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-right: none;

  }
}

.left-summary {
  border-right: 1px solid var(--darkbluesemi);
}

.summary-button {
  padding: 6px 10px;
  width: 200px;
  border-radius: 30px;
  border: 1px solid var(--darkblue);
  color: var(--darkblue);
  margin-right: 15px;
  transition: var(--tran2);
}

.summary-button:hover {
  color: #ffffff;
  background-color: var(--darkblue);
}

.summary-table .form-control {
  width: 100px;
}

/* 


.summary-section {
  margin-top: 20px;
}

.summary-section h3 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #444;
}

.summary-table {
  width: 100%;

  margin-bottom: 20px;
  font-size: 13px;
}

.summary-table th,
.summary-table td {
  font-size: 14px;
  padding: 5px 5px;
  border: 1px solid #dee2e6;
}

.summary-table th {
  background-color: #ebecee;
  color: #000000;
}

.summary-table .form-control {
  width: 100px;
}

.accommodation-details,
.meal-details {
  border: #dee2e6 solid 1px;
  padding: 10px;
  margin-bottom: 10px;
}
.btngroup {
  margin: 0;
}

.summary-button {
  padding: 10px 20px;
  color: #fff;
  background-color: #066666;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  margin-left: 10px;
  display: flex;
}

.summary-button:hover {
  background-color: #054949;
}

.input-group {
  margin-bottom: 10px;
}
.input-group .form-control {
  width: 80px;
}
.input-group-text {
  border: none;
  background: none;
  padding: 0px;
  padding-right: 10px;
  width: 230px;
  margin-bottom: 10px;
} */

.toast-notification {
  position: fixed;
  bottom: 20px; /* Adjust this to move it up or down */
  left: 50%;
  transform: translateX(-50%);
  background-color: #323232;
  color: #fff;
  padding: 12px 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  z-index: 1000;
  opacity: 0.9;
  animation: fadeInOut 3s ease-in-out;
}

.btngroup {
  display: flex;
  gap: 10px;            
}

.btn {    
  flex: 1;      
  max-width: 290px;       
}


@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0.9;
  }
  90% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
  }
}
