.addForm {
    background-color: var(--aqua);
    border-radius: 1.5rem;
    padding: 3rem;
    box-shadow: var(--shadow);
    display: flex;
    flex-direction: column;
    border: 1px solid var(--darkbluesemi);

}

.addForm h2 {
    font-family: "Raleway", sans-serif;
    font-size: 1.3em;
    color: var(--darkblue);
}

.addForm .formdata label {
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    color: var(--darkblue);
    font-size: 1.3rem;
}

.addForm .formdata input,
.addForm .formdata select {
    border-color: var(--darkbluesemi);
    border-radius: 5px;
    color: #888;
    height: 35px;
    padding: 0 15px;

}

.formdata {
    display: inline !important;
    align-items: center;
}

.mainMealsServed {
    margin-left: 20px !important;
    display: block !important;
}

.mainEmissionBoundary,
.mainWaterProvision {
    gap: 30px !important;
    margin-left: 20px !important;
    display: block !important;
}

.Map_Iocn {
    position: absolute;
    right: 3rem;
    top: 4rem;
    color: var(--darkblue);

}

.mainMealsServed p,
.EmissionBoundary p,
.SubEmissionBoundary p,
.sub-child-container p,
.WaterProvision p {
    font-size: 13px;
    margin-bottom: 5px;
}

.form-control {
    font-size: 13px;
}

.fa-info-circle {
    font-size: 15px;
    color: #5a5a5a;
    cursor: pointer;
}

.tooltip-icon1 {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-left: 5px;
}

.tooltip-icon1 .tooltip-text1 {
    visibility: hidden;
    opacity: 0;
    width: 250px;
    background-color: var(--aqua);
    box-shadow: var(--shadow);
    color: var(--darkblue);
    text-align: center;
    border-radius: 8px;
    padding: 10px;
    position: absolute;
    z-index: 10;
    top: 125%;
    /* Position the tooltip above the icon */
    left: 50%;
    transition: all 0.3s;
    font-size: 12px;
    line-height: 1.5;
    text-wrap: wrap;
}

.tooltip-icon1:hover .tooltip-text1 {
    visibility: visible;
    opacity: 1;
}

.save-button:hover,
.next-button:hover {
  color: #ffffff !important;
  background-color: var(--darkblue);
}

.checkboxGroup {
  margin-bottom: 10px;
}

.event-check {
  font-size: 13px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  cursor: pointer;
  position: relative;
  width: 18px;
  height: 18px;
  -webkit-tap-highlight-color: transparent;
  transform: translate3d(0, 0, 0);
}

.event-check svg {
  position: relative;
  z-index: 1;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #c8ccd4;
  stroke-width: 1;
  transform: translate3d(0, 0, 0);
  transition: all 0.2s ease;
}

.event-check svg path {
  stroke-dasharray: 60;
  stroke-dashoffset: 0;
}

.event-check svg polyline {
  stroke-dasharray: 22;
  stroke-dashoffset: 66;
}

.event-check:hover svg {
  stroke: var(--darkblue);
}

input[type="checkbox"]:checked+.event-check svg {
  stroke: var(--darkblue);
}

input[type="checkbox"]:checked+.event-checksvg path {
  stroke-dashoffset: 60;
  transition: all 0.3s linear;
}

input[type="checkbox"]:checked+.event-check svg polyline {
  stroke-dashoffset: 42;
  transition: all 0.2s linear;
  transition-delay: 0.15s;
}

.radio-label {
  display: flex;
  position: relative;
}

.form-check-input {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 1px;
  height: 1px;
  opacity: 0;
  z-index: 1;
}

.radio-text {
  margin-left: 10px;
  padding: 0px;
}

.radio-design {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: linear-gradient(to right bottom, var(--darkblue), hsl(225, 51%, 61%));
  position: relative;
  top: 4px
}

.radio-design::before {
  content: '';
  display: inline-block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: hsl(0, 0%, 100%);
  transform: scale(1.1);
  transition: .3s;
}

@keyframes stretch-animation {
  0% {
    transform: scale(1,1)
  }
  10% {
    transform: scale(1.1,.9)
  }
  30% {
    transform: scale(.9,1.1)
  }
  50% {
    transform: scale(1.05,.95)
  }
  100% {
    transform: scale(1,1)
  }
}