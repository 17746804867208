.marquee-container {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 100%;
    height: auto;
    background-color: white;
    display: flex;
    gap: var(--gap);
}

.marquee-image {
    height: 50px;
    margin: 0 40px;
}

.marquee-second-container {
    background-color: white;
    padding: 20px;
}