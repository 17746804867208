/* General container styles */
.resources-container {
    padding: 15px;
    font-family: "Arial", sans-serif;
    background: #bbe6ec;
    min-height: 100vh;
    color: #bbe6ec;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
  
  /* Heading styles */
  .resources-heading {
    
    text-align: center;
    margin-bottom: 20px;
    font-size: 5rem;
    color: #2e688d;
    animation: fadeIn 1.5s ease-in-out;
  }
  
  /* Grid styles */
  /* .resources-grid {
    display: flex;
    gap: 30px;
    justify-content: center;
    flex-wrap: wrap;
  } */
  
  /* Card styles */
  .resource-card {
    background: rgba(255, 255, 255, 0.712);
    border: 1px solid;
    border-radius: 10px;
    width: 420px;
    padding: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s, box-shadow 0.3s;
    /* animation: slideIn 1s ease-in-out; */
    text-align: center;
    /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
  }
  
  .resource-card:hover {
    transform: translateY(-10px) scale(1.03);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  }
  
  /* Image styles */
  .resource-image {
    width: 600px;
    height: 200px;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  /* Title styles */
  .resource-title {
    height: 5.2rem;
    font-size: 2rem;
    color: #2e688d;
    align-content: center;
    justify-items: center;
  }
  
  /* Description styles */
  .resource-description {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #555;
  }
  
  /* Button styles */
  .read-more-button {
    background: #2e688d;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 1.5rem;
    display: block;
    margin: 0 auto;
    transition: background 0.3s, transform 0.2s;
    display: inline-block;
    width: fit-content;
    max-width: 200px;
    white-space: nowrap;
  }
  
  .read-more-button:hover {
    background: #265674;
    transform: scale(1.1);
    color: white;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* @keyframes slideIn {
    from {
      transform: translateY(50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  } */
  
  .feature_swiper_container1 {
    height: 70vh;
    width: 85%;
    padding: 2rem 0;
    position: relative;
    border-radius: 10px;
    display: inline-block;
}

@media (max-width: 768px) {
  .feature_swiper_container1 {
    /* Adjust the space between slides for smaller screens */
    --swiper-space-between: 15px;
  }
}
