/* Overall Page Styling */
.calevent-page {
    text-align: center;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  /* Title */
  .caltitle {
    font-size: 28px;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Event Container */
  .calevents-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  /* Event Card */
  .calevent-card {
    width: 180px;
    border: 2px solid;
    border-radius: 8px;
    overflow: hidden;
    text-align: center;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
  }
  
  .calevent-card:hover {
    transform: scale(1.05);
  }
  
  /* Event Header */
  .calevent-header {
    color: white;
    padding: 15px;
    font-size: 16px;
    font-weight: bold;
  }
  
  /* Event Date */
  .calevent-date {
    font-size: 30px;
    display: block;
    font-weight: bold;
  }
  
  /* Event Month */
  .calevent-month {
    font-size: 14px;
  }
  
  /* Event Body */
  .calevent-body {
    padding: 15px;
  }
  
  .calevent-body h3 {
    font-size: 18px;
    margin: 5px 0;
  }
  
  .calevent-body p {
    font-size: 14px;
    color: #666;
  }
  