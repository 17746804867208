.new-event-page {
    height: 100%;
}

.segmented-control {
    width: 28rem;
    height: 4.5rem;
    box-shadow: 0.3rem 0.3rem 0.6rem var(--greyLight-2), -0.2rem -0.2rem 0.5rem var(--white);
    background-color: var(--aqua);
    border: 1px solid var(--white);
    border-radius: 4.5rem;
    display: flex;
    align-items: center;
    position: relative;
}

.segmented-control input {
    display: none;
}

.segmented-control>input:checked+label {
    transition: all 0.5s ease;
    color: var(--darkblue);

}

.segmented-control-1,
.segmented-control-2 {
    width: 14rem;
    height: 3.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--greyDark);
    transition: all 0.5s ease;
    z-index: 1;
}

.segmented-control-1 p,
.segmented-control-2 p {
    font-size: 1.4rem;
    font-weight: 500;
    padding-top: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.segmented-control-1:hover,
.segmented-control-2:hover {
    color: var(--darkblue);
}

.segmented-control-color {
    background-color: #f0f2fb;
    position: absolute;
    height: 3.4rem;
    width: 13rem;
    margin-left: 0.5rem;
    border-radius: 3.4rem;
    box-shadow: inset 0.2rem 0.2rem 0.5rem var(--greyLight-2), inset -0.2rem -0.2rem 0.5rem var(--white);
    pointer-events: none;
}

#tab-1:checked~.segmented-control-color {
    transform: translateX(0);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

#tab-2:checked~.segmented-control-color {
    transform: translateX(13.9rem);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mainselectOptionofRow{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.selectOptionofRow{
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.mainpagination{
    max-width: 100%;
}

.edit-button {
    font-size: 12px;
    display: inline-block;
    width: 30px;
    height: 30px;
    padding-top: 4px;
    text-align: center;
    color: var(--darkblue);
    border: 1px solid var(--darkblue);
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;

    &:hover {
        border-color: var(--indigo);
        color: var(--indigo);
    }
}

.grey-box {
    background-color: #fff;
    padding: 2.2rem 2.8rem;
    border-radius: 8px;
}

.search-events {
    font-family: FontAwesome, var(--fdesc);
    background-color: var(--aqua);
    color: var(--darkblue);
    padding: 6px 15px;
    border: none;
    transition: all 240ms ease-out;
    width: 20rem;
    position: relative;
    border-radius: 1.5rem;
    overflow: hidden;
    box-shadow: inset -2px -2px 4px rgba(255, 255, 255, .7), inset 1px 1px 3px #c5d4e3, inset 2px 2px 6px #c5d4e3;

    &:focus {
        outline: none;
        background-color: #f0f2fb;
    }

    &::placeholder {
        opacity: 0.6;
    }
}

.event-table {
    box-shadow: var(--shadow);
    margin-bottom: 10px;
}

.table-header tr th {
    background-color: var(--darkblue);
    color: var(--white);
    font-weight: normal;
    font-size: 1.5rem;
    padding: 1.5rem;
    font-family: var(--fhead);
    border-left: 1px solid #fff;

    &:first-child {
        border-left: none;
        border-top-left-radius: 1rem;
    }

    &:last-child {
        border-top-right-radius: 1rem;
    }
}

.table-body tr td {
    background-color: var(--aqua);
    color: var(--darkblue);
    font-weight: 300;
    font-family: var(--fdesc);
    font-size: 1.5rem;
    border: none;
    padding: 1rem;
}

.no-record {
    background-color: var(--aqua);
}

.event-details-button {
    padding: .6rem 2rem !important;
    width: 16rem !important;
    border: 1px solid var(--darkbluesemi);
    font-weight: 400 !important;
    color: var(--darkblue);
    width: 20rem;

    &:hover {
        background-color: var(--darkblue);
        color: var(--white);
    }
}

.threedots:after {
    content: "\2807";
    font-size: 20px;
}

.popup-option {
    width: 3rem;
    height: 2.5rem;
    border-radius: 0.5rem;
    box-shadow: 0.3rem 0.3rem 0.6rem var(--greyLight-2), -0.2rem -0.2rem 0.5rem var(--white);
    justify-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: box-shadow 0.3s ease;
    color: var(--darkblue);

    &:hover {
        font-weight: 600;
    }

    &:active {
        box-shadow: inset 0.2rem 0.2rem 0.5rem var(--greyLight-2), inset -0.2rem -0.2rem 0.5rem var(--white);
    }
}

.dropdown-menu {
    background-color: var(--aqua);
    padding: 1.0rem 0rem;
    font-size: 1.3rem;
    box-shadow: var(--shadow);
    border: none;
    border-radius: 1rem;
}

.dropdown-menu a {
    color: var(--darkblue);
    padding: .5rem 3rem;

    &:hover {
        color: var(--darkblue);
    }

    &:active {
        background-color: #fff;
    }


}

.pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    float: right;
}

.page-button {
    margin: 0 10px;
    padding: 4px 8px;
    color: var(--darkblue);
    cursor: pointer;

    &:hover {
        background-color: none;
    }
}

.page-button:disabled {
    cursor: default;
    opacity: 0.5;

}

.page-button.active {
    color: var(--indigo);
    background-color: none;
}

.page-button:hover:enabled {
    color: var(--indigo);
    background-color: none;
}

.pagination span {
    margin: 0 5px;
    font-size: 14px;
    font-weight: bold;
}

/* @media screen and (max-width: 600px) {
    .events-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .header-right {
        align-items: flex-start;
    }

    .events-table {
        width: 100%;
    }

    .events-table table,
    .events-table thead,
    .events-table tbody,
    .events-table th,
    .events-table td,
    .events-table tr {
        display: block;
    }

    .events-table thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .events-table tr {
        border: 1px solid #ddd;
        margin-bottom: 5px;
    }

    .events-table td {
        border: none;
        border-bottom: 1px solid #ddd;
        position: relative;
        padding-left: 50%;
        text-align: right;
    }

    .events-table td:before {
        position: absolute;
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        text-align: left;
        content: attr(data-label);
        font-weight: bold;
    }

    .events-table td.no-record {
        text-align: center;
        padding-left: 0;
    }
} */