
.manu-main-container{
  background-color: #0E0E0E;
  background-image: url(./assets/manu/trees.jpg);
  background-repeat: no-repeat;
  background-size: cover;
 
  background-attachment: fixed; 
  min-height: 100vh; 
  overflow: auto; 
}


.new-manu-logo{
  height: 70px;
  width: auto;
  top: 2%;
  position: absolute;
  left: 50%;
 
}


/* homepage */

.manu-home {
  height: 100vh;
  width: 100vw;
}

.manu-animation {
  position: relative;
  height: 100%;
  width: 100%;
}

.manu-animation img {
  position: absolute;
  z-index: 2;
}

.footprint-img{
  top: 12%;
  height: 620px;
  width: auto;
}

.vignette {
  height: 100%;
  width: 100%;
  object-fit: fill;
}

.Map {

  width: 1200px;
 left: 10%;
  height: 600px;
 
}

.manu-text {
  position: absolute;
  top: 40%;
  left: 46%;
  z-index: 3;
  height: 50vh;
  transition: opacity 0.5s ease; 
  opacity: 1; 
}

.manu-text.hidden {
  opacity: 0; 
}

.manu-text p {
  font-family: "Oswald";
  text-transform: uppercase;
  font-weight: bold;
  width: 100%;
  font-size: 44px;
  text-align: left;
  color: white;
}

.manu-text p span {
  color: #02BD41;
  font-weight: 800;
  
  font-size: 52px;
}

.new-text {
  position: absolute;
  top: 40%;
  left: 46%;
  z-index: 4;
  font-family: "Oswald";
  font-size: 44px;
  font-weight: bold;
  color: white;
  text-align: left;
  opacity: 0; 
  animation: fadeIn 1s ease-in-out forwards; 
}

.new-text p span{
  color: #02BD41;
  font-weight: 800;
  font-family: "Ephesis", cursive;
  font-size: 64px;
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

/* pages */

.grid-container {
  font-family: "Oswald";
  display: grid;
  grid-template-areas:
    "box-1 box-2 box-4"
    "box-1 box-3 box-4"
    "box-1 box-5 box-5";
  grid-template-columns: 1fr 1.5fr 1fr;
  grid-template-rows: 0.5fr 2.5fr 1fr; 
  gap: 20px;
  margin-top: 5px;
 
  height: 100vh;
  padding: 70px;
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  position: relative; 
  
  z-index: 1; 
  background: linear-gradient(
    180deg,
    rgba(0, 69.49, 40.53, 0.70) 0%,
    rgba(0, 9, 4, 0.70) 100%
  );
  border-radius: 27px;
  padding: 20px;
}

.box::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 27px; 
  padding: 1px; 
  background: linear-gradient(
    to bottom,
    rgb(193, 193, 193) 30%,
    rgb(1, 139, 47)
   
  );
  -webkit-mask: 
    linear-gradient(white, white) content-box, 
    linear-gradient(white, white);
  -webkit-mask-composite: xor; 
  mask-composite: exclude; 
  z-index: -1; 
}


.box-1 {
  display: flex;
  flex-direction: column;
  gap: 15px;
  grid-area: box-1;
  width: 470px;
}

.box-1 h2{
  color: #B4B4B4;
  font-size: 34px;
}

.box-1 img {
  height: 470px;
  width: auto;
}

.box-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: box-2;
  color: #02bd41;
  font-size: 38px;
  font-weight: bold;
  background-color: none;
  border-radius: none;
}

.box-3 {
  grid-area: box-3;
}

.box-4 {
  grid-area: box-4;
  font-family: "Oxanium";
  padding: 10px;
}

.gear2 {
  height: 35px;
  width: 35px;
  flex-shrink: 0;

  margin-top: -7px;
}


.box-4 ul li {
  display: flex;
  align-items: center;
  font-size: 16px;
  gap: 10px;
  margin-left: -20px;
  min-height: 50px;
}

.box-highlighted-text span {
  color: #02bd41;
  font-size: 28px;
  margin-left: 50px;
}

.box-5 {
  grid-area: box-5;
  display: flex;
  gap: 50px;
  font-family: "Oxanium";
  text-align: center;
}

.box-5 span {
  color: #02bd41;
}

.box-5 p {
  font-size: 14px;
}





/* BMWpage */

.cage {
  background-color: #171717;
  overflow: auto;
  height: 100vh;
}

.cage-container {
  margin-top: 40px;
  display: flex;
  background-color: #171717;
  justify-content: center;
  align-items: center;
  padding: 15px;
  gap: 70px;
  font-family: "Oswald";
}

.manu-logo-pages {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.manu-logo-pages img {
  height: 70px;
  margin-left: 10px;
  z-index: 2;
}

.manu-emissions-image {
  display: flex;
  gap: 20px;
  padding: 20px;
  margin-bottom: -70px;
  margin-top: -20px;
  justify-content: center;
  align-items: center;
  font-family: "Quicksand", sans-serif;
}

.manu-images {
  height: 140px;
  width: 140px;
  flex-shrink: 0;
}

.manu-images2 {
  height: 200px;
  width: 200px;
  flex-shrink: 0;
  margin-bottom: -10px;
}

.manu-emissions {
  display: flex;
  gap: 20px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  font-family: "Quicksand", sans-serif;
}

.manu-emissions p {
  font-size: 18px;
}

.car-container {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.car-container img {
  height: 100%; 
  width: 100%; 
  object-fit: cover; /* Ensures the image covers the screen without distortion */
  object-position: bottom;

}

.car-text p {
  color: #02bd41;
  font-family: "Oswald";
  font-size: 40px;
}

.manu-bottom {
  margin-top: -320px;
  margin-left: 500px;
}

.electronics-label {
  position: absolute;
  font-size: 22px;
  font-weight: 500;
  color: #ffffff;
  font-family: "Quicksand";
  padding: 5px;
  width: 110%;
  text-align: center;
  width: 220px;
  z-index: 3;
}

.electronics-label-o {
  position: absolute;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  font-family: "Quicksand";
  padding: 5px;
  width: 110%;
  text-align: center;
  width: 220px;
  z-index: 3;
}

.annual-reduction {
  bottom: 25%;
  width: 25%;
  text-align: left;
  left: 7%;
}

.total-product {
  top: 21%;
  width: 18%;
  text-align: right;
  left: 17%;
}

.manu-energy-consumption {
  top: 26%;
  width: 20%;
  text-align: left;
  right: 31%;
}


.carbon-emission {
  bottom: 25%;
  width: 20%;
  text-align: left;
  right: 3%;
}

.electronics-text {
  font-family: "Oswald";
  font-size: 38px;
  font-weight: bold;
  color: #02bd41;
}

.electronics-text-o {
  font-family: "Oswald";
  font-size: 32px;
  font-weight: bold;
  color: #ffffff;
}

@media screen and (max-width: 1225px) {

  .grid-container {
    grid-template-areas:
    "box-1 box-2 box-4"
    "box-1 box-3 box-4"
    "box-1 box-5 box-5";
  grid-template-columns: 1fr 1.5fr 1fr; /* Reduce box-1 width */
  grid-template-rows: auto;
  gap: 20px;
  padding: 50px;
  }

 
   .box-1 {
    width: auto;
    max-width: 350px; 
  }

  .box-1 h2 {
    font-size: 28px; 
  }

  .box-1 img {
    max-height: 300px; 
    width: auto;
  }

 
  .box-3 img {
    width: 100%;
    max-width: 500px;
    height: auto;
  }

 
  .box-4 {
    font-size: 16px;
    padding: 10px;
  }

  .box-4 ul {
    gap: 8px; 
  }

  .box-4 ul li {
    font-size: 14px; 
    word-wrap: break-word; 
  }

  .box-highlighted-text span {
    font-size: 22px; 
  }

  .gear2 {
    height: 30px; 
    width: 30px;
  }

  .manu-text {
    top: 30%;
  }

  .manu-text p{
    font-size: 36px;
  }

  
.manu-text p span {
  font-size: 46px;
}

.new-text {
  top: 30%;
  font-size: 36px;
}

.new-text p span {
  font-size: 56px;
}

.footprint-img {
  height: 520px;
}

/* BMW */

.manu-energy-consumption {
  top: 20%;
  right: 23%;
}

.carbon-emission {
  top: 20%;
  bottom:auto;
}

.car-container img {
  height: 100%; 
  width: 100%; 
  object-fit: cover; /* Ensures the image covers the screen without distortion */
  object-position: center;
}
}

@media screen and (max-width: 1080px) {
  .footprint-img {
    height: 480px;
    left: -20px;
  }

  .annual-reduction {
    left: 3%;
    width: 30%;
  }

  .total-product {
    top: 16%;
    left: 8%;
    width: 20%;
  }


  .grid-container {
    grid-template-areas:
    "box-1 box-2 box-4"
    "box-1 box-3 box-4"
    "box-1 box-5 box-5";
  grid-template-columns: 1fr 1.5fr 1fr; /* Reduce box-1 width */
  grid-template-rows: auto;
  gap: 20px;
  padding: 50px;
  }

 
   .box-1 {
    width: auto;
    max-width: 360px; 
  }

  .box-1 h2 {
    font-size: 28px; 
  }

  .box-1 img {
    max-height: 300px; 
    width: auto;
  }

 
  
 
  .box-3 img {
    width: 100%;
    max-width: 400px;
    height: auto;
  }

 
  .box-4 {
    font-size: 15px;
    padding: 10px;
  }

  .box-4 ul {
    gap: 8px; 
  }

  .box-4 ul li {
    font-size: 13px; 
    word-wrap: break-word; 
  }

  .box-highlighted-text span {
    font-size: 22px; 
  }

  .gear2 {
    height: 30px; 
    width: 30px;
  }
  .box-5 p {
    font-size: 12px;
  }

}

@media screen and (max-width: 768px) {
  .manu-main-container {
    background-color: #0e0e0e;
    background-image: url(./assets/manu/trees.jpg);

    background-size: cover;
    background-position: center;
    height: 100vh;
    overflow-x: hidden;
  }

  .grid-container {
    grid-template-areas:
      "box-1"
      "box-2"
      "box-3"
      "box-4"
      "box-5";
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 30px;
    padding: 30px;
  }


  
  .footprint-img {
    left: 10%;
    right: 10%;
    top: 40px;
    height: 380px;
  }

  .manu-text {
    top: 400px;
    left: 20%;
    right: 20%;
    height: auto;
  }

  .manu-text p{
    font-size: 30px;
  }

  .manu-text p span {
    font-size: 36px;
  }

  .new-text {
    top: 400px;
    left: 20%;
    right: 25%;
    font-size: 30px;
    font-weight: 500;
    overflow: auto;
    line-height: 1.5;
  }
  
  .new-text p span {
    font-size: 46px;
  }

  .new-manu-logo {
    height: 40px;
    width: auto;
    top: 2%;
    position: absolute;
    left: 45%;
  }

  /* BMW */
  .cage {
    background-color: #151515;
  }

  .manu-logo-pages {
    position: absolute;
    left: 40%;
  }

  .manu-logo-pages img {
    height: 40px;
  }

  .car-container {
    position: static;
    height: auto;
    width: 100%;
  }

  .electronics-label {
    position: static;
    font-size: 18px;
    width: 90%;
    text-align: left;
    border-radius: 20px;
    border: 1px solid #aaa;
    margin: 10px auto;
  }

  .electronics-label-o {
    position: static;
    font-size: 16px;
    width: 90%;
    text-align: left;
    border-radius: 20px;
    border: 1px solid #aaa;
    margin: 10px auto;
  }
  
  .annual-reduction, .total-product, .manu-energy-consumption, .carbon-emission {
    width: 90%;
    padding: 0 25px;
  }

  .electronics-text {
    font-size: 28px;
  }
  
  .electronics-text-o {
    font-size: 22px;
  }

  .grid-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 40px;
    padding: 15px;
  }

  .manu-logo {
    position: static;
    margin-bottom: 20px;
  }

  .box {
    width: 100%;
    max-width: 400px;
  }

  .box-1 {
    order: 6;
  }

  .box-2 {
    order: 2;
  }

  .box-3 {
    order: 3;
  }

  .box-3 img {
    max-width: 100%;
  }

  .box-5 {
    grid-area: box-5;
    display: flex;
    gap: 50px;
    font-family: "Oxanium";
    text-align: center;
  }

  .box-4 {
    order: 4;
  }

  .box-4 ul li {
    font-size: 14px;
  }
  .box-highlighted-text span {
    font-size: 18px;
  }

  .box-5 {
    order: 5;
    display: flex;
    flex-direction: column;
   
  }

  .box-5 {
    gap: 15px;
  }

  .box-5 p {
    font-size: 12px;
  }
}

