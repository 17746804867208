.add-meeting-check {
    font-size: 13px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    cursor: pointer;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    transform: translate3d(0, 0, 0);
  }
  
  .add-meeting-check svg {
    position: relative;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #c8ccd4;
    stroke-width: 1;
    transform: translate3d(0, 0, 0);
    transition: all 0.2s ease;
  }
  
  .add-meeting-check svg path {
    stroke-dasharray: 60;
    stroke-dashoffset: 0;
  }
  
  .add-meeting-check svg polyline {
    stroke-dasharray: 22;
    stroke-dashoffset: 66;
  }
  
  .add-meeting-check:hover svg {
    stroke: var(--darkblue);
  }
  
  input[type="checkbox"]:checked+.add-meeting-check svg {
    stroke: var(--darkblue);
  }
  
  input[type="checkbox"]:checked+.add-meeting-check svg polyline {
    stroke-dashoffset: 42;
    transition: all 0.2s linear;
    transition-delay: 0.15s;
  }

  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* For smooth scrolling on iOS */
  }
  