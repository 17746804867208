.waste-calculation-table {
  border: 1px solid #eee;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.waste-calculation-table th {
  background-color: var(--darkblue);
  color: var(--white);
  font-size: 15px;
  font-weight: 300;
  border-right: 1px solid #fff;

  &:first-child {
    border-top-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-right: none
  }
}

.waste-calculation-table td {
  color: var(--darkblue);
  font-size: 13px;
}

.waste-calculation-table input,
.waste-calculation-table select {
  border-color: var(--darkbluesemi);
  border-radius: 5px;
  color: #888;
  height: 30px;
  padding: 0 15px
}

.table-footer p {
  font-size: 12px;
  color: #000;
}

.save {
  background: var(--darkblue);
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.save:hover {
  background: var(--darkblue);
}


.fa-info-circle {
  font-size: 15px;
  color: #5a5a5a;
  cursor: pointer;
}
