.pre-planning-form {
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 0;
}

.pre-planning-form label {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 1.3rem;
}

.pre-planning-form input,
.pre-planning-form select {
  border-color: var(--darkbluesemi);
  border-radius: 5px;
  color: #888;
  height: 35px;
  padding: 0 15px
}

.eventSpace {
  display: flex;
  flex-direction: row;
  gap: 8px
}

/* .PrePlanning-form {
  padding: 20px;
  margin: 20px auto;
  width: 50%;
  font-family: "Barlow condensed", sans-serif;
  font-style: normal;
}

.pform-container h2 {
  color: #363636;
}

.pform-group {
  font-size: 17px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  width: 80%;
  gap: 30px;
}

.pform-group label {
  flex: 1;
  min-width: 280px;
}

.pform-group input {
  flex: 2;
  width: 60%;
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(7, 7, 7, 0.4);
  outline: none;
  transition: box-shadow 0.5s ease, transform 0.5s ease;
  font-size: 10px;
}

.pform-group select {
    flex: 1;
    width: 40%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px;
    font-size: large;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(7, 7, 7, 0.4);
    outline: none;
    transition: box-shadow 0.5s ease, transform 0.5s ease;
    font-size: 10px;
}

.pform-group input::placeholder {
  margin-left: 15px;
  color: #838282;
}

.pform-group input:focus {
  animation: input-focus-animation 0.5s ease;
}

.psave {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 600;
  font-size: 17px;
  color: #007272;
  border: 1px solid;
  text-align: center;
  margin: 0px;
  width: 80px;
  height: 28px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 4px 4px 0px #00000040, 2px 4px 0px 0px #000000bd;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.psave:hover {
  background-color: #bbb;
}

button:hover {
  background-color: #005353;
} */


@keyframes input-focus-animation {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

.access-restricted {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  padding: 20px;
  text-align: center;
  border: 2px solid var(--darkblue);
  border-radius: 5px;
  background-color: #f9f9f9;
  margin: 20px auto;
  width: fit-content;
}

.fa-info-circle {
  font-size: 15px;
  color: #5a5a5a;
  cursor: pointer;
}

.tooltip-icon2 {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-left: 5px;
}

.tooltip-icon2 .tooltip-text2 {
  visibility: hidden;
  opacity: 0;
  width: 250px;
  background-color: var(--aqua);
  box-shadow: var(--shadow);
  color: var(--darkblue);
  text-align: center;
  border-radius: 8px;
  padding: 10px;
  position: absolute;
  z-index: 10;
  bottom: 125%;
  /* Position the tooltip above the icon */
  right: -125px;
  transition: all 0.3s;
  font-size: 12px;
  line-height: 1.5;
}

.tooltip-icon2:hover .tooltip-text2 {
  visibility: visible;
  opacity: 1;
}

.save-button,
.next-button {
  padding: 0px 20px;
  width: 160px;
  border-radius: 3px;
  border: 1px solid var(--darkblue);
  color: var(--darkblue);
}

.next-button {
  margin-left: 10px;
}


.save-button:hover,
.next-button:hover {
  color: #ffffff !important;
  background-color: var(--darkblue);
}