.meal-consumption label {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--darkblue);
  font-size: 1.3rem;
}

.meal-consumption input,
.meal-consumption select {
  border-color: var(--darkbluesemi);
  border-radius: 5px;
  color: #888;
  height: 35px;
  padding: 0 15px;
}

.save {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 600;
  font-size: 17px;
  color: #007272;
  border: 1px solid;
  text-align: center;
  margin: 0px;
  width: 80px;
  height: 28px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 4px 4px 0px #00000040, 2px 4px 0px 0px #000000bd;
  cursor: pointer;
  display: flex;
  /* Use flexbox */
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
}

.save:hover {
  background-color: #bbb;
}

.Serves_Txt {
  width: 100%;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid var(--darkbluesemi);
  border-left: none;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 0px 10px;
  font-weight: 600;
  font-size: 15px;
  line-height: 30px;
}