

.about-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    height: 100vh;
    background-color: #bbe6ec;
  }
  

  .video-container {
   
    justify-content: center;
   
    margin: 20px 0;
  }

  .video-container h1{
    font-family: "Oswald";
    font-size: 32px;
  }
  
  .responsive-video {
   margin-top: 15px;
    border-radius: 10px;
    box-shadow: var(--shadow);
  }
  
 
  

  
  @media (max-width: 768px) {
    .responsive-video {
      width: 100%;
      height: auto;
    }
  
    .info-section p {
      font-size: 16px;
    }
  }
  