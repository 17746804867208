.No_data {
  border: #dddddd solid 1px;
  text-align: center;
}

.rowTitle {
  font-family: "Raleway", sans-serif;
  font-size: 1.6rem!important;
  color: var(--greyLight-3);
  font-weight: 600!important;
}

.MeetingBoxWrp label {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--darkblue);
  font-size: 1.4rem;
}

.meeting-input-label {
  box-shadow: inset -2px -2px 4px rgba(255, 255, 255, .7), inset 1px 1px 3px #c5d4e3, inset 2px 2px 6px #c5d4e3;
  width: 12rem;
  padding: 8px 15px;
  border-radius: 1.5rem;
  text-align: right;
  background-color: #f0f2fb;
  font-size: 1.3rem!important;
}

.TableWrp table {
  border-radius: 1rem;
}

.TableWrp th {
  background-color: var(--darkblue);
  color: var(--white);
  font-size: 15px;
  font-weight: 300;
  border-right: 1px solid #fff;

  &:first-child {
    border-top-left-radius: 1rem;
  }

  &:last-child {
    border-top-right-radius: 1rem;
    border-right: none;
  }
}

.TableWrp td {
  color: var(--darkblue);
  font-size: 13px;
}