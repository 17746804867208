body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f7f9fc;
    color: #333;
  }
  
  .terms-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    animation: fadeIn 1s ease-in-out;
  }
  
  .title {
    text-align: center;
    color: rgb(48, 82, 99);
    margin-bottom: 20px;
  }
  
  .section {
    margin-bottom: 20px;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    animation: slideIn 0.8s ease-in-out;
  }
  
  .section-title {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: rgb(48, 82, 99);
  }
  
  .section-content {
    font-size: 1em;
    line-height: 1.5;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(-20px);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  