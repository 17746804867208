.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f4f8;
}

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  height: 100vh; 
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.image-section {
  flex: 1;
  background: url('./assets/Picture1.png') no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align items to the top */
  align-items: center;
  color: black;
  padding: 20px;
}

.image-section h1 {
  margin: 20px 0;
  font-size: 1.3rem;
  text-align: center;
}

.form-section {
  flex: 1;
  padding: 20px; 
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    height: auto; 
  }
  .image-section, .form-section {
    flex: none;
    width: 100%;
  }
}