.containers-add {
  font-family: "Quicksand", sans-serif;
  display: flex;
  justify-content: space-between;
  padding: 40px;
  margin-top: -15px;
}

.event-name {
  font-size: 24px;
  text-transform: uppercase;
  text-align: right;
  margin-bottom: 25px;
  color: var(--darblue);
}

.forms-section {
  width: 44%;
  padding: 10px 25px !important;
  background: var(--aqua) !important;
  border-radius: 10px;
}

.forms-section img {
  height: 40px;
  margin-bottom: 40px;
}

.check {
  cursor: pointer;
  position: relative;
  margin: auto;
  width: 18px;
  height: 18px;
  -webkit-tap-highlight-color: transparent;
  transform: translate3d(0, 0, 0);
}

.check:before {
  content: "";
  position: absolute;
  top: -15px;
  left: -15px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: rgba(34, 50, 84, 0.03);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.check svg {
  position: relative;
  z-index: 1;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #c8ccd4;
  stroke-width: 1.5;
  transform: translate3d(0, 0, 0);
  transition: all 0.2s ease;
}

.check svg path {
  stroke-dasharray: 60;
  stroke-dashoffset: 0;
}

.check svg polyline {
  stroke-dasharray: 22;
  stroke-dashoffset: 66;
}

.check:hover:before {
  opacity: 1;
}

.check:hover svg {
  stroke: #4285f4;
}

input[type="checkbox"]:checked + .check svg {
  stroke: #4285f4;
}

input[type="checkbox"]:checked + .check svg path {
  stroke-dashoffset: 60;
  transition: all 0.3s linear;
}

input[type="checkbox"]:checked + .check svg polyline {
  stroke-dashoffset: 42;
  transition: all 0.2s linear;
  transition-delay: 0.15s;
}

.questions-my {
  display: flex;
  margin: 20px 0;
  font-weight: bold;
  font-size: 16px;
}

.questions-my-duration {
  display: flex;
  margin: 35px 0;
  font-weight: bold;
  font-size: 16px;
}

.radios-group-my {
  font-size: 14px;
  display: flex;
  justify-content: left;
  gap: 65px;
  margin-bottom: 35px;
}

.radios-group1-my {
  font-size: 14px;
  display: flex;
  justify-content: left;
  gap: 50px;
  margin-bottom: 35px;
}

.radios-group2-my {
  font-size: 14px;
  display: flex;
  justify-content: left;
  gap: 50px;
  margin-bottom: 35px;
  margin-top: 15px;
  margin-left: 0px;
}

.radios-group-my label,
.radios-group1-my label {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.inpuut {
  border: 2px solid transparent;
  width: 25em;
  height: 2.8em;
  padding-left: 0.8em;
  outline: none;
  overflow: hidden;
  background-color: #f3f3f3;
  border: 1px solid rgb(134, 134, 134);
  border-radius: 10px;
  font-size: 14px;
  transition: all 0.5s;
}

.inpuut:hover,
.inpuut:focus {
  border: 2px solid #4a9dec;
  box-shadow: 0px 0px 0px 7px rgb(74, 157, 236, 20%);
  background-color: white;
}

.locations-icon {
  margin-right: 10px;
  margin-top: 5px;
}

.question-loc {
  display: flex;
}

.duration-container-my select {
  border: 2px solid transparent;
  border: 1px solid rgb(134, 134, 134);
  width: 30%;
  height: 2.8em;
  transition: all 0.5s;
  padding-left: 0.8em;
  background-color: #f3f3f3;
  border-radius: 10px;
  font-size: 14px;
}

.duration-container-my select:focus {
  border-color: #007272;
  outline: none;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .duration-container-my select {
    width: 90%; /* Make the dropdown wider on smaller screens */
  }
}

@media screen and (max-width: 480px) {
  .duration-container-my select {
    width: 90%; /* Make the dropdown even wider on very small screens */
  }
}

.night-text {
  margin-left: 10px;
  font-size: 14px;
}

.details-section-my {
  overflow: hidden;
  width: 44%;
  height: 680px;
  background: #fcfcfc;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 15px;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.details-section-my h3 {
  font-family: "Oswald", sans-serif;
  color: #636363;
  margin-top: 20px;
  margin-left: -300px;
  font-size: 30px;
  text-align: left;
}

.details-section-my h3 span {
  font-size: 18px;
}

.car-image-my {
  height: 250px;
  margin-bottom: 140px;
  transition: 0.5s ease-in-out;
  overflow: hidden;
}

.car-my {
  margin-top: -28px;
  height: 400px;
  width: 650px;
}

.bus-my {
  margin-top: -100px;
  height: 400px;
  width: 650px;
}

.train-my {
  margin-top: -100px;
  height: 400px;
  width: 650px;
}

.flight-my {
  margin-top: -145px;
  height: 400px;
  width: 650px;
  overflow: hidden;
}

.journey-details-my {
  display: flex;
  height: 150px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: -160px;
  margin-bottom: 0px;
}

.journey-line-my {
  border-bottom: 1px dashed rgb(116, 116, 116);
  display: flex;
  padding: 10px;
  align-items: center;
  width: 100%;
  align-items: center;
  /* Vertically centers the content */
  justify-content: center;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.start-journey-my {
  width: 35%;
  text-align: center;
  margin-left: 30px;
  font-size: 14px;
}

.journey-line-my img {
  margin: 2px;
  height: 30px;
  margin-left: -10px;
  width: auto;
}

.end-journey-my {
  width: 35%;
  text-align: center;
  margin-left: 2px;
  font-size: 14px;
}

.date-meal-my {
  display: flex;
  gap: 10px;
  margin-left: -100px;
  margin-right: 50px;
}

.date-meal-details-my {
  display: flex;
  justify-content: center;
  align-items: center;
}

.date-box-my {
  display: flex;
  background: #ffffff;
  margin-left: 150px;
  padding: 2px;
  border-radius: 6px;
  margin-top: -5px;
}

.start-date-box-my,
.end-date-box-my {
  margin: 0 8px;
  width: 70px;
  text-align: center;
  position: relative;
}

.start-date-box-my::before,
.end-date-box-my::before {
  content: "";
  position: absolute;
  top: 25px;
  left: 0;
  width: 100%;
  height: 10px;
  border-radius: 10px;
}

.start-date-box-my::before {
  background-color: #6ccdbf;
}

.end-date-box-my::before {
  background-color: #f78d89;
}

.label-my {
  display: block;
  font-size: 10px;
  font-weight: 600;
  color: #6ccdbf;
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  position: relative;
}

.end-date-box-my .label-my {
  color: #f78d89;
}

.date-my {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  position: relative;
}

.day-my {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.month-my,
.year-my {
  font-size: 12px;
  color: #777;
}

.meal-type-my {
  margin-top: 25px;
}

.emission-section-my {
  outline: 1px solid rgb(212, 212, 212);
  display: flex;
  background: #f2f2f2;
  height: auto;
  margin-top: 95px;
  border-radius: 10px;
  text-align: center;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
  overflow: hidden; /* Prevents overflow issues */
}

.emission-meter-my {
  font-family: "Oswald", sans-serif;
  color: #3e3e3e;
  text-align: left;
  font-size: 20px;
  margin-left: -30px;
  font-weight: 600;
  margin-top: -7px;
}

.data-list-my {
  margin-left: -20px;
  margin-top: 5px;
  padding: 10px;
}

.meter-my {
  padding: 20px;
  height: 50px;
  width: auto;
  margin-top: -25px;
  margin-left: -20px;
  display: flex;
  justify-content: center;
}

.emission-value-my {
  margin-top: 0px;
  text-align: left;
  font-size: 28px;
  font-weight: bold;
}

.emission-level-my {
  text-align: left;
  font-size: 11px;
  width: 110%;
}

.btnn {
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
  margin-top: 0px !important;
  padding: 10px 30px;
  width: 150px;
  font-weight: 500;
  background: rgb(0, 117, 185);
  color: rgb(1, 37, 105);
  border: rgb(1, 37, 105);
  position: relative;
  overflow: hidden;
  border-radius: 0.6em;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.transition {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 500ms;
  background-color: rgba(16, 134, 185, 0.6);
  border-radius: 9999px;
  width: 0;
  height: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.btnn:hover .transition {
  width: 14em;
  height: 14em;
}

.btnn:active {
  transform: scale(1);
}

@media screen and (max-width: 1328px) {
  .containers-add {
    flex-direction: column;
    margin: 20px;
    padding: 20px;
  }

  .forms-section {
    width: 100%;
    margin: 10px 0;
  }

  .details-section-my {
    width: 100%;
  }

  .inputs-group {
    flex-direction: row;
    gap: 10px;
  }

  .radios-group-my,
  .radios-group1-my,
  .radios-group2-my {
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 30px;
  }

 
  .journey-line-my img {
    margin: 2px;
    height: 15px;
    width: auto;
  }
}

@media screen and (max-width: 768px) {
  .containers-add {
    margin: 0;
    padding: 0px;
  }

  .forms-section img {
    height: 30px;
    margin-bottom: 20px;
  }

  .radios-group-my,
  .radios-group1-my,
  .radios-group2-my {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    justify-items: start;
  }

  .btnn {
    padding: 0.5em 1.5em;
  }

  .duration-container-my {
    margin-bottom: 15px;
    margin-top: -15px;
    margin-left: 0;
  }

  .duration-container-my select {
    width: 60%; /* Changed to full width for better responsiveness */
    height: 3.5em;
    transition: all 0.5s;
    padding-left: 0.8em;
    background-color: #f3f3f3;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 10px;
  }

  .duration-container-my select:focus {
    border-color: #007272;
    outline: none;
  }

  .details-section-my {
    width: 126%;
    margin-left: -30px;
    overflow: hidden;
    margin-bottom: -30px;
    height: 515px;
    border-radius: 10px;
    box-shadow: none;
  }

  .details-section-my h3 {
    margin-top: 20px;
    margin-left: -10px;
    font-size: 18px;
    text-align: left;
  }

  .details-section-my h3 span {
    font-size: 16x;
  }

  .car-my {
    margin-top: -30px;
    height: 360px;
    width: 450px;
  }
  
  .bus-my {
    margin-top: -100px;
    height: 350px;
    width: 450px;
  }
  
  .train-my {
    margin-top: -100px;
    height: 350px;
    width: 450px;
  }
  
  .flight-my {
    margin-top: -145px;
    height: 350px;
    width: 450px;
    overflow: hidden;
  }
  
 
  .journey-details-my {
    height: 90px;
  }

  .start-journey-my {
    width: 40%;
    text-align: center;
    font-size: 10px;
    margin-left: 0px;
  }

  .end-journey-my {
    width: 40%;
    text-align: center;
    font-size: 10px;
    margin-left: 0px;
  }

  .date-meal-my {
    flex-direction: column;
    align-items: center;
    margin-top: -15px;
  }

  .date-box-my {
    margin: 0;
    padding: 2px;

    border-radius: 6px;
    margin-top: -25px;
  }

  .start-date-box-my,
  .end-date-box-my {
    margin: 0 10px;
    width: 55px;
    text-align: center;
    position: relative;
  }

  .start-date-box-my::before,
  .end-date-box-my::before {
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
    height: 10px;
    border-radius: 10px;
  }

  .label-my {
    font-size: 8px;
    margin-bottom: 8px;
  }

  .date-my {
    padding: 8px;
  }

  .day-my {
    font-size: 14px;
  }

  .month-my,
  .year-my {
    font-size: 8px;
  }

  .meal-type-my {
    margin-top: 10px;
  }

  .emission-section-my {
    flex-direction: column;

    height: 16vh;
    align-items: flex-start;
  }

  .meter-my {
    margin-top: -20px;
    margin-left: -40px;
  }

  .emission-meter-my {
    font-size: 12px;
    margin-left: 0px;
    text-align: left;
  }

  .data-list-my {
    margin-left: 130px;
    margin-top: -70px;
    width: 80%;
    text-align: left;
  }

  .emission-value-my {
    font-size: 12px;
    text-align: left;
  }

  .emission-level-my {
    font-size: 10px;
    text-align: left;
    width: 60%;
  }
  .flight-my {
    margin-top: -80px;
  }
}

@media screen and (max-width: 480px) {
  .date-meal-my {
    flex-direction: row;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
  }

  .date-box-my {
    margin: 0;
    padding: 2px;
    margin-top: 0;
  }

  .start-date-box-my,
  .end-date-box-my {
    margin: 0 5px;
    width: 50px;
  }

  .label-my {
    font-size: 7px;
    margin-bottom: 6px;
  }

  .date-my {
    padding: 6px;
  }

  .day-my {
    font-size: 12px;
  }

  .month-my,
  .year-my {
    font-size: 7px;
  }

  .meal-type-my {
    margin-top: 8px;
  }

  .emission-section-my {
    padding: 5px;
  }

  .emission-meter-my {
    font-size: 14px;
  }

  .emission-value-my {
    font-size: 14px;
  }

  .emission-level-my {
    font-size: 10px;
  }
}
