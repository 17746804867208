.event-details-top-navbar {
    background-image: linear-gradient(#387ba5 13%, #2e688d 40%, #214860 86%);
    border-top: 1px solid #387ba5;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    /* height: 5rem; */
    width: 100%;
    box-shadow: -.5rem -.5rem 1rem hsl(0 0% 100% / .75);
}

.event-details-top-navbar ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

.event-details-top-navbar ul li {
    width: 17rem;
    height: 100%;
    display: inline-block;
    border-left: 1px solid #367aa4;
    border-right: 0.1rem solid black;

    &:last-child {
        box-shadow: 1px 0px 0 0 #367aa4;
    }

    &:first-child {
        box-shadow: -1px 0px 0 0 black;
    }
}

.event-details-top-navbar ul li a {
    height: 100%;
    width: 100%;
    color: #ddd;
    cursor: pointer;
    font-size: 15px;
    text-decoration: none;
    font-family: "Raleway", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0;
}

.event-details-top-navbar ul li a:hover {
    color: var(--white);
}

.event-details-top-navbar ul li a.navbar-link-active {
    color: var(--darkblue);
    font-weight: 500;
    cursor: default;
    background-image: linear-gradient(#f5f7ff 13%, #eceef8 40%, #e4e8f6 86%);
}

.sub-sections {
    width: 100%;
    background: var(--aqua);
    padding: 0;
    margin: 0;
    border-left: 1px solid var(--darkbluesemi);
    border-right: 1px solid var(--darkbluesemi);
}

.sub-sections ul {
    margin: 0px;
    padding: 10px;
    list-style: none;
    /* height: 45px; */
}

.sub-sections ul li {
    display: inline-block;
}

.sub-sections ul li a {
    height: 100%;
    color: #888;
    cursor: pointer;
    padding: 14px 20px;
    text-decoration: none;
    font-size: 16px;
    font-weight: 300;
    font-family: "Rubik", sans-serif;
}

.sub-sections ul li a img {
    width: 20px !important;
    display: none !important;
}

.sub-sections ul li a:hover {
    color: var(--darkblue);
}

.sub-sections ul li a.navbar-subsection-active {
    color: var(--darkblue);
    font-weight: 400;
    border-bottom: 1px solid var(--darkblue);
    z-index: 1;
}


@media (max-width: 768px) {
    .event-details-top-navbar ul li {
        display: block !important;
        width: 100%;
        &:last-child {
            box-shadow: none;
        }
    
        &:first-child {
            box-shadow: none;
        }
    }

    .event-details-top-navbar ul li a.navbar-subsection-active {
        background: none;
        color: #ffffff;
    }

    .sub-sections ul li {
        display: block;
        width: 100%;
        text-align: center;
    }

    .sub-sections ul li a.navbar-subsection-active {
        color: #000000;
    }
}