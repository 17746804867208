.alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.alert-modal {
  background-color: var(--aqua);
  border-radius: 1.2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.575);
  transition: 0.3s;
  width: 400px;
  max-width: 90%;
  padding: 0.6rem;
  outline: none;
}

/* Modal content */
.alert-content {
  display: flex;
  flex-direction: column;
}

/* Header section */
.alert-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  background-color: var(--greyLight-3);
  border-radius: 0.6rem 0.6rem 0 0;
}

.alert-header h5 {
  color: var(--white);
}

.alert-title {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

/* Close button */
.alert-close-button {
  position: relative;
  top: -10px;
  border: none;
  font-size: 24px;
  color: var(--white);
  cursor: pointer;
}

/* Modal body */
.alert-body {
  padding: 20px;
  font-size: 16px;
  color: #555;
}

/* Footer section with buttons */
.alert-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 0 10px 10px 0;
}

/* Cancel button */
.alert-cancel-button {
  padding: 8px 16px;
  background-color: #e0e0e0;
  color: #333;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Confirm button */
.alert-confirm-button {
  padding: 8px 16px;
  background-color: var(--darkblue);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Button hover effects */
.alert-cancel-button:hover {
  background-color: #ccc;
}

.alert-confirm-button:hover {
  background-color: var(--darkteal);
}

/* ---------------Animation---------------- */
.slit-in-vertical {
  -webkit-animation: slit-in-vertical 0.45s ease-out both;
  animation: slit-in-vertical 0.45s ease-out both;
}

@-webkit-keyframes slit-in-vertical {
  0% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
    transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }

  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
    transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
  }
}

@keyframes slit-in-vertical {
  0% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
    transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }

  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
    transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
  }
}