:root {
    /*colors*/
    --indigo: #272846;
    --indigosemi: #27284679;
    --white: #fefeff;
    --aqua: #e4e8f6;
    --aquasemi: #e3f7f630;
    --greyLight-1: #e4ebf5;
    --greyLight-2: #c8d0e7;
    --greyLight-3: #454d65;
    --greyDark: #9baacf;
    /* --skyblue: #b5cfd3; */
    --skyblue: #bbe6ec;
    --skybluesemi: #bbe6ec30;
    --darkblue: #2e688d;
    --darkbluesemi: #2e688d88;
    --darkbluesemi2: #2e688dee;
    --darkblueinvi: #2e698d00;
    --darkteal: #256385;
    /* fonts */
    --fdesc: "Rubik", "lato", sans-serif;
    --fhead: "Raleway", serif;
    --fs0: 4.2rem;
    --fs1: 3.6rem;
    --fs2: 2.4rem;
    --fs3: 2.2rem;
    --fs4: 2rem;
    --fs5: 1.5rem;
    --fs6: 1.4rem;
    --fs7: 1.3rem;
    /* shadows */
    --shadow: -0.5rem -0.5rem 1rem hsl(0 0% 100% / 0.75), 0.5rem 0.5rem 1rem hsl(0 0% 50% / 0.5);
    --shadow1: 0 2px 8px hsla(0, 0%, 0%, 0.195);
    --shadow2: 2px 4px 8px hsla(215, 20%, 16%, 0.15);
    /* transitions */
    --tran1: 0.25s ease;
    --tran2: 0.5s ease;
    --tran3: 1s ease;
    --cubicin: cubic-bezier(0.51, 0.03, 0.64, 0.28);
    --cubicout: cubic-bezier(0.33, 0.85, 0.4, 0.96);
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: inherit;
}

input,
button {
    background: none;
    border: none;
    font: inherit;
}

.button {
    cursor: pointer;
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}

li {
    list-style: none;
}

html {
    font-family: var(--fdesc);
    font-size: 10px;
    scroll-behavior: smooth;
}

body {
    background-color: var(--white);
    color: var(--darkteal);
    font-size: 1.6rem;
    line-height: 1.75;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: hsl(0, 4%, 90%);
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #2e698ddc;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #2e698d;
}

.landing-container {
    height: 100vh;
    width: 100%;
    overflow-y: scroll;
    /* scroll-snap-type: y mandatory; */
}

.slides {
    display: flex;
    flex-direction: column;
    justify-content: center;
    scroll-snap-align: start;
    height: 100vh;
    width: 100%;
}

.slide1 {
    background-image: linear-gradient( left, var(--skyblue), var(--skyblue) 70%, transparent 70%, transparent 100%);
    background-image: -webkit-linear-gradient( left, var(--skyblue), var(--skyblue) 70%, transparent 70%, transparent 100%);
}

.slide2 {
    background-image: linear-gradient( to right, transparent 30%, var(--skyblue) 30%, var(--skyblue) 100%), url("./assets/backanim.png");
    background-position: left;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.slide3 {
    background-image: linear-gradient( to left, transparent 30%, var(--skyblue) 30%, var(--skyblue) 100%), url("./assets/backanim2.png");
    background-position: left;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.slide4 {
    background-image: linear-gradient( to right, transparent 30%, var(--skyblue) 30%, var(--skyblue) 100%), url("./assets/backanim.png");
    background-position: left;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.header {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    padding-block: var(--fs1);
}

.navbar-left {
    flex: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: var(--fs1);
}

.navbar-right {
    flex: 30%;
}

.logo {
    width: 100px;
    height: 40px;
}

.hamburger-menu {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 20px;
    height: 15px;
    margin-top: 15px;
    cursor: pointer;
    margin-right: 10px;
}

.hamburger-menu .bar {
    height: 2px;
    background-color: var(--darkblue);
    margin-top: -5px;
    transition: 0.3s;
}

.navlist {
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.navitem {
    display: inline-block;
}

.navlinks {
    text-transform: uppercase;
    font-size: var(--fs5);
    text-decoration: none;
    color: #282936;
    font-weight: 400;
    letter-spacing: 0.3rem;
    transition: var(--tran1);
}

.navlinks:hover {
    color: var(--white);
    text-shadow: var(--indigosemi) 0px 0px 5px;
}

.btn {
    font-size: var(--fs5);
    font-weight: 500;
    border-radius: 50px;
    padding: 8px 20px;
    cursor: pointer;
}

.btn-light {
    width: 100px;
    background: #36454f;
    color: white;
    outline: 1px solid var(--darkblueinvi);
    outline-offset: -1px;
}

.btn-light:hover {
    outline-color: var(--darkblue);
    background-color: var(--aqua);
    color: var(--indigo);
}

@media (max-width: 768px) {
    .navlist {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 80px;
        right: 10px;
        background-color: var(--white);
        width: 250px;
        padding: 20px;
        transition: all 0.3s ease-in-out;
    }
    .navlist.open {
        display: flex;
    }
    .hamburger-menu {
        display: flex;
        margin-left: -20px;
    }
    .navbar-left {
        flex: 1;
        justify-content: space-between;
    }
    .navbar-right {
        flex: 0;
    }
    .btn-light {
        position: relative;
        left: 0;
    }
}

.login-btn {
    padding: 0 15px;
    display: flex;
    justify-content: right;
}

.btn {
    margin: 0;
    text-align: center;
    font-size: var(--fs5);
    font-weight: 500;
    border-radius: 50px;
    padding: 8px 20px;
    transition: var(--tran2);
    cursor: pointer;
}

.btn-light {
    width: 100px;
    position: relative;
    left: -40px;
    background: #36454f;
    color: white;
    outline: 1px solid var(--darkblueinvi);
    outline-offset: -1px;
    &:hover {
        outline-color: var(--darkblue);
        background-color: var(--aqua);
        color: var(--indigo);
    }
}

.btn-dark {
    width: 135px;
    padding-left: 50px;
    background-color: var(--darkblue);
    color: var(--skyblue);
    &:hover {
        background-color: var(--indigo);
        color: var(--white);
    }
}

.main {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.main p {
    font-size: 16px;
}

.main-text {
    flex: 45%;
    padding: 0% 5% 10% 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.main-text h1 {
    text-transform: uppercase;
    font-family: "Oswald";
    color: #36454f;
    font-size: 48px;
    font-weight: 600;
}

.main-text h3 {
    font-size: 20px;
    margin-top: 10px;
}

button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    font-size: 16px;
    font-family: "Quicksand";
}

button.learn-more {
    width: 150px;
    height: auto;
}

button.learn-more .start-tracking-circle {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: block;
    margin-left: 120px;
    width: 3rem;
    height: 4rem;
    border: none;
    background: #282936;
    border-radius: 2rem;
}

button.learn-more .start-tracking-circle .icon {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    margin-left: -25px;
    bottom: 0;
    margin: auto;
    background: #fff;
}

button.learn-more .start-tracking-circle .icon.arrow {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    left: 0.625rem;
    width: 1.125rem;
    height: 0.1rem;
    background: none;
}

button.learn-more .start-tracking-circle .icon.arrow::before {
    position: absolute;
    content: "";
    top: -0.29rem;
    right: 0.0625rem;
    width: 0.625rem;
    height: 0.625rem;
    border-top: 0.125rem solid #fff;
    border-right: 0.125rem solid #fff;
    transform: rotate(45deg);
}

button.learn-more .button-text-l {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.75rem 0;
    color: #282936;
    font-weight: 600;
    line-height: 1.5;
    text-align: left;
}

button.learn-more:hover .start-tracking-circle {
    width: 120%;
    margin-left: 0px;
}

button.learn-more:hover .start-tracking-circle .icon.arrow {
    background: #fff;
    transform: translate(15rem, 0);
}

button.learn-more:hover .button-text-l {
    color: #fff;
    left: 20px;
}


/* button.learn-more {
  outline: none;
} */

button.learn-more:focus {
    outline: none;
}

.schedule-button {
    margin-right: 10px;
    margin-left: 10px;
    /* margin-top: 50px; */
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    background-color: #282936;
    color: white;
    font-size: 16px;
    border-radius: 20px;
    transition: white 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    cursor: pointer;
    font-family: "Quicksand", sans-serif;
}

.schedule-button:hover {
    background-color: #282936;
    transform: translateX(-5px);
}

.schedule-button .arrow {
    display: none;
    width: 20px;
    height: 20px;
    fill: #ffffff;
    opacity: 0;
    transform: translateX(-40px) rotate(-45deg);
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.schedule-button:hover .arrow {
    display: inline-block;
    opacity: 1;
    animation: slide-in 0.4s forwards;
}

@keyframes slide-in {
    from {
        transform: translateX(-60px) rotate(-45deg);
        opacity: 0.1;
    }
    to {
        transform: translateX(10px) rotate(-45deg);
        opacity: 1;
    }
}

.input-row {
    display: flex;
    justify-content: space-between;
}

.half-width {
    width: 48%;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: brightness(70%);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.popup-content {
    background: url("./assets/pricingback.png");
    background-size: contain;
    padding: 30px;
    border-radius: 10px;
    width: 600px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
    animation: slideDown 0.4s ease;
    position: relative;
}

@keyframes slideDown {
    from {
        transform: translateY(-50px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.close-icon {
    position: absolute;
    top: 10px;
    right: 50px;
    font-size: 40px;
    cursor: pointer;
    color: #000000;
}

.close-icon:hover {
    color: #333;
}

.popup-title {
    margin-bottom: 20px;
    font-size: 26px;
    font-family: "Oswald";
    margin-bottom: 30px;
    color: #333;
}

.popup-form .input-group {
    margin-bottom: 15px;
}

.popup-form label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #555;
}

.popup-form input,
.popup-form textarea {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    outline: none;
    transition: border 0.3s ease;
    background-color: white;
}

.popup-form input:focus,
.popup-form textarea:focus {
    border-color: #007bff;
}

.popup-form textarea {
    resize: none;
    height: 80px;
}

.popup-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.submit-button {
    padding: 10px 20px;
    width: 800px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    background-color: #282936;
    color: white;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #2e688d;
}

#scrollToTopBtn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    background-color: #3d3d3d;
    color: white;
    font-size: 2rem;
    border-radius: 50%;
    box-shadow: var(--shadow);
    display: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 100;
}

#scrollToTopBtn.show {
    display: flex !important;
}

.p27 {

    margin-top: 3%;
    margin-left: 3%;
    line-height: 1.3;
    font-size: calc(7px + 1em);
}

.button27 {
    --white: #ffe7ff;
    --purple-100: #6f6f70;
    --purple-200: #5e5e5e;
    --purple-300: #212121;
    --purple-400: #363636;
    --purple-500: #000000;
    --radius: 18px;
    border-radius: var(--radius);
    outline: none;
    cursor: pointer;
    font-size: 20px;
    font-family: Arial;
    background: transparent;
    letter-spacing: 0px;
    border: 0;
    position: relative;
    width: 400px;
    height: 50px;
}

.bg27 {
    position: absolute;
    inset: 0;
    border-radius: inherit;
    filter: blur(1px);
}

.bg27::before,
.bg27::after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: calc(var(--radius) * 1.1);
    background: var(--purple-500);
}



.wrap27 {
    border-radius: inherit;
    overflow: hidden;
    height: 100%;
    padding: 3px;
    background: linear-gradient(to bottom, var(--purple-100) 0%, var(--purple-400) 100%);
    position: relative;
    transition: all 0.3s ease;
}

.outline27 {
    position: absolute;
    overflow: hidden;
    inset: 0;
    opacity: 0;
    outline: none;
    border-radius: inherit;
    transition: all 0.4s ease;
}

.outline27::before {
    content: "";
    position: absolute;
    inset: 2px;
    width: 120px;
    height: 400px;
    margin: auto;
    background: linear-gradient(to right, transparent 0%, white 50%, transparent 100%);
    animation: spin 3s linear infinite;
    animation-play-state: paused;
}

.content27 {
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    position: relative;
    height: 100%;
    gap: 16px;
    border-radius: calc(var(--radius) * 0.85);
    font-weight: 600;
    transition: all 0.3s ease;
    background: linear-gradient(to bottom, var(--purple-300) 0%, var(--purple-400) 100%);
    box-shadow: inset -2px 12px 11px -5px var(--purple-200), inset 1px -3px 11px 0px rgb(0 0 0 / 35%);
}

.content27::before {
    content: "";
    inset: 0;
    position: absolute;
    z-index: 10;
    width: 80%;
    top: 45%;
    bottom: 35%;
    opacity: 0.7;
    margin: auto;
    background: linear-gradient(to bottom, transparent, var(--purple-400));
    filter: brightness(1.3) blur(5px);
}

.char27 {
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.char27 span {
    display: block;
    color: transparent;
    position: relative;
}

.char27 span:nth-child(5) {
    margin-left: 1px;
}

.char27.state-1-27 span:nth-child(5) {
    margin-right: 0px;
}

.char27.state-1-27 span {
    animation: charAppear 1.2s ease backwards calc(var(--i) * 0.03s);
}

.char27.state-1-27 span::before,
.char27 span::after {
    content: attr(data-label);
    position: absolute;
    color: var(--white);
    text-shadow: -1px 1px 2px var(--purple-500);
    left: 0;
}

.char27 span::before {
    opacity: 0;
    transform: translateY(-100%);
}

.icon27 {
    animation: resetArrow 0.8s cubic-bezier(0.7, -0.5, 0.3, 1.2) forwards;
    z-index: 10;
}

.icon27 div,
.icon27 div::before,
.icon27 div::after {
    height: 3px;
    border-radius: 1px;
    background-color: var(--white);
}

.icon27 div::before,
.icon27 div::after {
    content: "";
    position: absolute;
    right: 0;
    transform-origin: center right;
    width: 14px;
    border-radius: 15px;
    transition: all 0.3s ease;
}

.icon27 div {
    position: relative;
    width: 24px;
    box-shadow: -2px 2px 5px var(--purple-400);
    transform: scale(0.9);
    background: linear-gradient(to bottom, var(--white), var(--purple-100));
    animation: swingArrow 1s ease-in-out infinite;
    animation-play-state: paused;
}

.icon27 div::before {
    transform: rotate(44deg);
    top: 1px;
    box-shadow: 1px -2px 3px -1px var(--purple-400);
    animation: rotateArrowLine 1s linear infinite;
    animation-play-state: paused;
}

.icon27 div::after {
    bottom: 1px;
    transform: rotate(316deg);
    box-shadow: -2px 2px 3px 0 var(--purple-400);
    background: linear-gradient(200deg, var(--white), var(--purple-100));
    animation: rotateArrowLine2 1s linear infinite;
    animation-play-state: paused;
}

.path {
    position: absolute;
    z-index: 5;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%; /* Ensure it takes the full width of the container */
    transform: scaleX(1.8); /* Make the path 1.5 times wider */
    stroke-dasharray: 100 550;
    stroke-dashoffset: 150;
    pointer-events: none;
  }
.splash27 {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    stroke-dasharray: 60 60;
    stroke-dashoffset: 60;
    transform: translate(-17%, -31%);
    stroke: var(--purple-300);
}


/** STATES */

.button27 .words {
    opacity: 1;
}

.button27 .words span {
    animation-play-state: running;
}

.button27:hover .char27.state-1-27 span::before {
    animation: charAppear 0.7s ease calc(var(--i) * 0.03s);
}

.button27:hover .char27.state-1-27 span::after {
    opacity: 1;
    animation: charDisappear 0.7s ease calc(var(--i) * 0.03s);
}

.button27:hover .wrap27 {
    transform: translate(8px, -8px);
}

.button27 .outline27 {
    opacity: 1;
}

/* .button27:.outline27::before,
.button27:.icon27 div::before,
.button27:.icon27 div::after,
.button27: .icon27 div {
    animation-play-state: running;
} */

.button27 .outline27::before,
.button27 .icon27 div::before,
.button27 .icon27 div::after,
.button27 .icon27 div {
    animation-play-state: running;
}

.button27:active .bg27::before {
    filter: blur(5px);
    opacity: 0.7;
}

.button27:active .words,
.button27:active .outline27 {
    opacity: 0;
}

.button27:active .wrap27 {
    transform: translate(3px, -3px);
}


/* .button27:active .splash27 {
    animation: splash 0.8s cubic-bezier(0.3, 0, 0, 1) forwards 0.05s;
} */

.button27:focus .path27 {
    animation: path 1.6s ease forwards 0.2s;
}

.button27:focus .icon27 {
    animation: arrow 1s cubic-bezier(0.7, -0.5, 0.3, 1.5) forwards;
}

.char27.state-2 span::after,
.button27:focus .char27.state-1-27 span {
    animation: charDisappear 0.5s ease forwards calc(var(--i) * 0.03s);
}

.button27:focus .char27.state-2 span::after {
    animation: charAppear 1s ease backwards calc(var(--i) * 0.03s);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes charAppear {
    0% {
        transform: translateY(50%);
        opacity: 0;
        filter: blur(20px);
    }
    20% {
        transform: translateY(70%);
        opacity: 1;
    }
    50% {
        transform: translateY(-15%);
        opacity: 1;
        filter: blur(0);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes charDisappear {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(-70%);
        opacity: 0;
        filter: blur(3px);
    }
}

@keyframes arrow {
    0% {
        opacity: 1;
    }
    50% {
        transform: translateX(60px);
        opacity: 0;
    }
    51% {
        transform: translateX(-200px);
        opacity: 0;
    }
    100% {
        transform: translateX(-128px);
        opacity: 1;
    }
}

@keyframes swingArrow {
    50% {
        transform: translateX(5px) scale(0.9);
    }
}

@keyframes rotateArrowLine {
    50% {
        transform: rotate(30deg);
    }
    80% {
        transform: rotate(55deg);
    }
}

@keyframes rotateArrowLine2 {
    50% {
        transform: rotate(330deg);
    }
    80% {
        transform: rotate(300deg);
    }
}

@keyframes resetArrow {
    0% {
        transform: translateX(-128px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes path {
    from {
        stroke: white;
    }
    to {
        stroke-dashoffset: -480;
        stroke: #f9c6fe;
    }
}

@keyframes splash {
    to {
        stroke-dasharray: 2 60;
        stroke-dashoffset: -60;
    }
}

@media (max-width: 768px) {
    .popup-content {
        width: 100%;
        padding: 16px;
    }
    .half-width {
        width: 100%;
    }
    .popup-form input,
    .popup-form textarea {
        width: 100%;
        padding: 6px;
        border-radius: 5px;
        gap: 2px;
    }
    .popup-buttons {
        flex-direction: column;
        width: 100%;
    }
    .close-icon {
        top: 10px;
        right: 20px;
        font-size: 30px;
    }
    .submit-button {
        width: 100%;
        max-width: 100%;
    }
}

@media (max-width: 480px) {
    .popup-content {
        padding: 12px;
        max-width: 350px;
    }
    .submit-button {
        font-size: 14px;
    }
    .close-icon {
        font-size: 28px;
    }
}

.slide-right-content {
    align-content: center;
    padding: 15rem;
}

.form1 {
    flex: 60%;
}

.slide-left-content {
    align-content: center;
    padding: 15rem;
}

.heading1 {
    text-align: left;
    color: var(--indigo);
    font-size: var(--fs0);
    font-family: "Oswald";
    font-weight: 400;
    padding-bottom: 3rem;
    width: 100%;
}

.heading10 {
    text-align: center;
    color: var(--indigo);
    font-size: var(--fs0);
    font-family: "Oswald";
    font-weight: 400;
    padding-bottom: 3rem;
    width: 100%;
    background-color: var(--skyblue);
}

.slide-right-content p {
    text-align: justify;
    width: 100%;
}

.slide-right-content p span {
    font-size: 18px;
}

.slide-left-content p span {
    font-size: 18px;
}

.slide-left-content p {
    text-align: justify;
    width: 100%;
}

.image {
    display: flex;
    flex: 40%;
    justify-content: center;
    padding: 10px;
}

.image img {
    margin-top: 0px;
    height: 100%;
    width: 700px;
    object-fit: contain;
}

.rectangle-box {
    position: relative;
    margin-top: 15px;
    margin-left: -40px;
    background-color: rgb(255, 255, 255);
    width: 520px;
    overflow: hidden;
    clip-path: polygon( 40px 0%, 100% 0, 100% calc(100% - 40px), calc(100% - 30px) 100%, 0 100%, 0% 30px);
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    height: 692px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 5px 8px 0px;
}

.rectangle-box::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(./assets/anim/eventback.png);
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 10;
    opacity: 1;
}

.rectangle-box1 {
    position: relative;
    margin-top: 120px;
    margin-left: -40px;
    background-color: rgb(255, 255, 255);
    width: 520px;
    overflow: hidden;
    clip-path: polygon( 40px 0%, 100% 0, 100% calc(100% - 40px), calc(100% - 30px) 100%, 0 100%, 0% 30px);
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    height: 692px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 5px 8px 0px;
}

.rectangle-box1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(./assets/anim/eventback.png);
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 10;
    opacity: 1;
}

.paper-img {
    margin-top: -20px;
    height: 380px;
    padding: 10px;
    margin-left: -150px;
    width: auto;
}

.wrong-img {
    height: 200px;
    margin-top: -390px;
    margin-left: -320px;
}

.reg-img {
    margin-top: -30px;
    height: 400px;
    margin-left: -120px;
    width: auto;
}

.right-img {
    height: 120px;
    margin-top: -360px;
    margin-left: -290px;
}

.bottle-img {
    margin-top: 70px;
    margin-left: -120px;
    height: 320px;
    width: auto;
}

.meal-img {
    margin-left: -50px;
    margin-top: 80px;
    height: 280px;
}

.recbanner-img {
    height: 150px;
    margin-top: -400px;
    margin-left: -320px;
}

.banner-img {
    margin-top: 25px;
    height: 320px;
    margin-left: -120px;
    width: auto;
}

.ev-icon {
    height: 120px;
    margin-top: -40px;
    margin-left: -240px;
}

.ev-img {
    margin-top: -240px;
    margin-left: -50px;
    height: 520px;
    width: auto;
}

.bulb-img {
    margin-top: -10px;
    height: 320px;
    margin-left: -100px;
    width: auto;
}

.energy-img {
    height: 130px;
    margin-left: -250px;
    width: auto;
}

.monitor-img {
    height: 290px;
    margin-top: -120px;
    margin-left: -60px;
}

.text-paper {
    margin: 65px;
    color: rgb(0, 55, 49);
    text-align: left;
    font-size: 34px;
    font-weight: 900;
}

.sub-text-slide1 {
    margin: 65px;
    margin-top: -55px;
    font-size: 15px;
    text-align: left;
}

.reg-img,
.paper-img,
.bottle-img,
.wrong-img,
.right-img,
.recbanner-img,
.ev-img,
.bulb-img,
.energy-img,
.monitor-img,
.text-paper,
.ev-icon {
    position: relative;
    z-index: 1;
    opacity: 1;
    transition: all 2.5s cubic-bezier(0.105, 0.82, 0.165, 1);
}

.text-slide2 {
    margin: 65px;
    color: rgb(0, 55, 49);
    text-align: right;
    font-size: 34px;
    font-weight: 900;
}

.sub-text-slide2 {
    margin: 65px;
    margin-top: -55px;
    font-size: 15px;
    text-align: right;
}

.bag-img {
    height: 400px;
    margin-top: -50px;
    margin-left: -20px;
}

.cycle-img {
    margin-top: -120px;
    height: 450px;
}

.climate-img {
    height: 160px;
    margin-top: -220px;
    margin-left: -290px;
}

.clothes-img {
    height: 400px;
    margin-top: -40px;
}

.AC-img {
    height: 400px;
    margin-top: -80px;
}

.train-img {
    height: 540px;
    width: 540px;
    margin-top: -150px;
}

.low-img {
    height: 150px;
    margin-top: -220px;
    margin-left: -290px;
}

.food-img {
    height: 350px;
    margin-top: 5px;
    margin-left: -50px;
}

.shopping-img {
    margin-top: -40px;
}

.rrr-img {
    margin-top: -75px;
}

.plant-img {
    margin-top: -100px;
    height: 400px;
}

.bag-img,
.cycle-img,
.AC-img,
.train-img,
.low-img,
.food-img,
.shopping-img,
.rrr-img,
.plant-img,
.climate-img,
.clothes-img {
    position: relative;
    z-index: 1;
    opacity: 1;
    transition: all 2.5s cubic-bezier(0.105, 0.82, 0.165, 1);
}

.text-slide3 {
    margin: 65px;
    color: rgb(0, 55, 49);
    text-align: left;
    font-size: 34px;
    font-weight: 900;
}

.sub-text-slide3 {
    margin: 65px;
    margin-top: -55px;
    font-size: 15px;
    text-align: left;
}

.renew-img {
    height: 550px;
    margin-top: -150px;
    margin-left: -140px;
}

.efficiency-img {
    height: 500px;
    margin-top: -120px;
    margin-left: -100px;
}

.meet-img {
    height: 250px;
    margin-top: -80px;
    margin-left: -190px;
    z-index: 2000;
}

.evcar-img {
    height: 290px;
    margin-top: -120px;
    margin-left: -420px;
}

.shipping-img {
    height: 400px;
    margin-top: -140px;
    margin-left: -80px;
}

.supply-img {
    height: 400px;
    margin-top: -90px;
    margin-left: -80px;
}

.plasticbag-img {
    height: 460px;
    margin-top: -130px;
    margin-left: -80px;
}

.wastemethod-img {
    height: 440px;
    margin-top: -110px;
    margin-left: -40px;
}

.renew-img,
.efficiency-img,
.travel-img,
.shipping-img,
.supply-img,
.plasticbag-img,
.wastemethod-img {
    position: relative;
    z-index: 1;
    opacity: 1;
    transition: all 2.5s cubic-bezier(0.105, 0.82, 0.165, 1);
}

.features {
    max-width: 70rem;
    font-family: "Quicksand";
    margin: 10px auto;
}

.feature_swiper_container {
    height: 14rem;
    width: 100%;
    padding: 2rem 0;
    position: relative;
    border-radius: 10px;
    display: inline-block;
}

.swiper-slide {
    width: 40rem;
    height: 14rem;
    position: relative;
}

.swiper-slide p {
    width: 40rem;
    height: 14rem;
    background-color: var(--greyLight-3);
    color: #fff;
    border-radius: 10px;
    padding: 2rem;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
    display: none;
}

.inactive_feature_card {
    opacity: 0.6;
}

.feature-controller {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.feature-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 15px 0;
    color: var(--greyLight-3);
    transition: all 0.3s;
}

.feature-navigation:hover {
    color: #fff;
    background-color: #282936;
}

.pagination-container {
    padding-top: 12px;
}

.feature-pagination {
    position: relative;
    margin: 0 auto;
}

.feature-pagination .swiper-pagination-bullet {
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.feature-pagination .swiper-pagination-bullet-active {
    background: var(--greyLight-3);
}


/* Responsive adjustments */

@media (max-width: 480px) {
    .feature_swiper_container {
        height: 18rem;
    }
    .swiper-slide {
        width: 25rem;
        height: 18rem;
    }
    .swiper-slide p {
        width: 25rem;
        height: 18rem;
    }
}

.subheading1 {
    color: var(--darkblue);
    font-size: var(--fs3);
    font-family: var(--fdesc);
    font-weight: 400;
    margin-bottom: 40px;
    padding: 0;
    line-height: 85%;
}

.input1 {
    background-color: var(--white);
    border-radius: 8px;
    padding: 5px 15px;
    margin-top: 8px;
    width: 100%;
    color: var(--darkblue);
    font-size: var(--fs6);
    &:focus {
        outline: none;
    }
    &::placeholder {
        opacity: 0.5;
    }
}

.frgtpass-signin {
    display: flex;
    justify-content: space-between;
}

.frgtpass-signin>a,
button {
    margin-top: 15px;
    display: inline-block;
}

.frgtpass-signin a {
    letter-spacing: 1.5px;
    padding: 3px;
    transition: var(--tran1);
    &:hover {
        color: var(--indigo);
    }
}

.new-user {
    text-align: center;
}

.new-user .form-signup {
    font-weight: 500;
    color: var(--indigo);
    cursor: pointer;
}

.new-user p {
    display: inline-block;
    padding-right: 8px;
}

.slide5 {
    padding-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--skyblue);
}

.slide6 {
    padding-top: 50px;
    background-color: var(--skyblue);
}

.footer .heading1 {
    color: var(--white);
    font-size: var(--fs2);
    margin-top: 40px;
}

.footer .fa {
    padding: 12.5px;
    margin: 5px;
    font-size: 15px;
    width: 40px;
    text-align: center;
    text-decoration: none;
    border-radius: 50%;
    background-color: var(--white);
    color: var(--indigo);
    &:hover {
        color: var(--white);
        background-color: var(--indigo);
    }
}

.social-media-container {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px 20px 20px;
}

.social-media-feed {
    border-radius: 10px;
    background-color: #fff;
    padding: 12px;
    width: max-content;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@media screen and (max-width: 768px) {
    .navlist {
        flex-direction: column;
    }
    .navitem {
        display: block !important;
    }
    .header {
        top: 0rem;
    }
    .main {
        flex-direction: column;
        height: auto;
    }
    .main-text {
        flex: 100%;
        width: 100%;
    }
    .image {
        flex: 100%;
        width: 100%;
        margin-left: 10px;
    }
    .slide-right-content,
    .slide-left-content {
        flex: 100%;
        width: 100%;
        padding: 3rem;
    }
    .landing-container {
        height: auto;
    }
    .marrquee-box1 {
        margin-top: 20px;
    }
    .slides {
        height: auto;
    }
    .slide1,
    .slide2,
    .slide3,
    .slide4,
    .slide5,
    .slide6 {
        background-image: none;
        background-color: var(--skyblue);
    }
}

.clock-icon {
    position: fixed;
    bottom: 10px; /* 20px from the bottom */
    right: 10px; /* 20px from the right */
    width: 180px;
    height: 100px;
  }

  /* Calendar Popup Styles */
.calendar-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .calendar-container {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    width: 981px; /* Large Calendar */
    max-width: 90%;
    height: 575px;
    overflow-y: auto;
    overflow-x: hidden;
     
  }
  
  .close-btn {
    background: rgb(0, 0, 0);
    color: white;
    border: none;
    padding: 8px 10px;
    font-size: 10px;
    border-radius: 5px;
    cursor: pointer;
    float: right;
  }
  
  .sx-react-calendar-wrapper {
    width: 1200px;
    max-width: 100vw;
    height: 800px;
    max-height: 90vh;
  }