
.pricing-table-container {
  font-family: "Quicksand";
  width: 80%; 
  background-color: #fff;
  border-radius: 2rem;
}

.pricing-table {
  border-radius: 2rem;
  border-collapse: collapse;
  position: sticky;
  text-align: center;
  margin-top: -10px;
  box-shadow: rgba(14, 63, 126, 0.06) 0px 0px 0px 1px, rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 2px 2px -1px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px, rgba(42, 51, 70, 0.03) 0px 10px 10px -5px, rgba(42, 51, 70, 0.03) 0px 24px 24px -8px;
}

.pricing-table thead th {
 font-family: "Oswald";
  background-color: #fff;
  color: #36454f;
  padding: 10px 20px; 
  border-bottom: 2px solid #ddd;
}

.pricing-table thead th:first-child {
  border-top-left-radius: 2rem;
}

.pricing-table thead th:last-child {
  border-top-right-radius: 2rem;
}

.pricing-table tr:last-child td {
  border-bottom: none;
}


.feature-column {
  text-align: left;
  padding-left: 10px;
}

.compare-section h4 {
  font-size: 28px;
}

.compare-section p {
  font-family: "Quicksand";
  
  font-size: 14px;
  font-weight: 400;
  width: 90%;
}

.event-type{
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.billed-text{
  display: flex;
  align-items: center;

  flex-direction: row;
  margin-bottom: -5px
}

.event-type h4{
  font-size: 12px;
  font-family: "Quicksand";
  margin-left: 5px;
}

.pricing-header h2 {
  font-size: 28px;

}

.pricing-header span {
  width: 130px;
  background-color: #ffffff;
  padding: 5px 10px;
  font-family: "Quicksand";
  color: #36454f;
  font-size: 16px;
  border: none;
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  border-radius: 10px;
}

.pricing-header span span {
 font-size: 12px;
}

.choose-plan-btn {
  width: 150px;
  background-color: #36454f;
  padding: 5px 10px;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 10px;
  outline: 0;
  font-weight:400 ;
  border: 0;
  border-radius: 10px;
}

.choose-plan-btn:hover {
  background-color: #333;
}

.pricing-table thead {
  background-color: #fff; 
  position: sticky;
  border-radius: 10px;
  top: 0; 
  z-index: 2; 
}

.pricing-table tbody tr td {
  padding: 10px 20px; 
  font-size: 12px;
  border-bottom: 1px solid #ddd;
}

.pricing-table tbody td:first-child {
  border: none; 
}


.pricing-table th:first-child {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #ddd;
  box-shadow: none;
}


.pricing-table td:first-child {
  font-weight: 500;
  color: #36454f;
  text-align: left;
  width: 30%; 
}

/* .pricing-table tbody tr td:nth-child(3) {
  border-left: 2px solid rgb(74, 200, 239); 
  border-right:2px solid rgb(74, 200, 239); 

} */

.single-event{
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.single-event h4{
  font-size: 14px;
  margin-bottom: 5px;
  text-align: center;
  color: #767676;
}

.single-event p{
  margin-top: 8px;
  font-size: 14px;
  text-align: center;
}

@media (max-width: 768px) {
  .pricing-table-container {
    overflow-x: auto; 
    background-color: #fff;
    padding: 0;
  }

  .compare-section p {
   display: none;
  }
  .pricing-table {
    width: 1200px; 
    margin-left: 0;
    margin-right: 0;
    background-color: #fff;
  }

  .pricing-table thead {
    position: sticky;
    top: 0;
    z-index: 2;
  }
}

