.lato-regular {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.lato-bold {
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.lato-black {
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-style: normal;
}

.main-content {
    width: 100%;


}

.White_Box {
    background: #ffffff;
    border: #ced4da solid 1px;
    border-radius: 0px;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 0;
}

.AddEventBtn {
    color: #ffffff;
    font-size: 12px;
    font-weight: 600;
    float: right;
    background: var(--darkblue);
    margin-left: 10px;
    height: 37px;

}

.AddEventBtn:hover {
    color: #ffffff;

}

.AddBtn {
    align-items: center;
    background-color: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: .25rem;
    box-shadow: var(--shadow);
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: inline-flex;
    font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    line-height: 1.25;
    margin: 0;
    min-height: 3rem;
    padding: calc(.875rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: auto;
}

.AddBtn:hover,
.AddBtn:focus {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: rgba(0, 0, 0, 0.65);
}

.AddBtn:hover {
    transform: translateY(-1px);
}

.AddBtn:active {
    background-color: #F0F0F1;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    color: rgba(0, 0, 0, 0.65);
    transform: translateY(0);
}

.GreenBtn {
    align-items: center;
    background-color: #454d65;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: .5rem !important;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: var(--white);
    cursor: pointer;
    display: inline-flex;
    font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    line-height: 1.25;
    margin-right: 10px !important;
    min-height: 3rem;
    padding: calc(.875rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: auto;
}

.GreenBtn:hover,
.GreenBtn:focus {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.2) 0 4px 12px;
    color: var(--white);
    transform: scale(1.025);
}


.GreenBtn:active {
    background-color: #F0F0F1;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    color: rgba(0, 0, 0, 0.65);
    transform: translateY(0);
}



@media (max-width: 420px) {
    .button-84 {
        height: 48px;
    }
}

.PageNo {
    font-size: 14px;
}


.pageDetailsWrp {
    border-bottom: #ced4da solid 1px;
    padding: 5px 10px;
    margin-bottom: 20px;
    font-size: 35px;
    font-weight: 600;
    text-align: right;
    color: var(--darkblue);
}

.Page_Title h3 {
    color: #000000;
    font-weight: 600;
    font-size: 17px;
}

.input-group-text {
    font-size: 13px;
}

.UploadBtn {
    color: #ffffff;
    font-size: 12px;
    font-weight: 600;
    background: var(--darkblue);
    margin-left: 10px;
    height: 37px;
}

.UploadBtn:hover {
    color: #ffffff;

}

.GreenLineBtn {
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    cursor: pointer;
    display: inline-flex;
    font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    line-height: 1.25;
    margin-right: 10px !important;
    min-height: 3rem;
    padding: calc(.875rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: auto;

    color: var(--darkblue);
    border-radius: .5rem !important;
    border: 1px solid var(--darkblue);
    background: #ffffff;
}

.GreenLineBtn:hover {
    color: #000000;
    background: #ffffff !important;
    transform: scale(1.025);

}

.edit-button:hover {
    color: #000000;
    background: #ffffff !important;
}

.hideDiv {
    display: none !important;
}

.W100px {
    width: 100px;
}

.Total_Meal_Count {
    display: none;
}

/* .PaiChartWrp .card{
    height: 200px!important;
    max-height: 200px!important;
}
.PaiChartWrp .chart-container{
    height: auto;
    max-height: auto;  
}  */

.card {
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    align-items: left;
    padding: 15px;
    display: block;
}

.attendee-emission-card {
    border-radius: 1.5rem;
    color: var(--darkblue);
    background-color: var(--aqua);
    box-shadow: var(--shadow);
    height: 290px;
}

.attendee-emission-card .attendee-card-header-title {
    font-family: "Raleway", sans-serif;
    font-size: 1.5rem;
    line-height: 1.25;
    color: var(--darkbluesemi2);
    padding: 20px 25px;
}

.attendee-card-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 6rem;
    border-bottom: 1px solid #bfc4d3;
}

.attendee-value {
    padding: 20px 25px;
}

.dashboard-value p {
    font-size: 22px;
    font-family: "Rubik", sans-serif;
    font-weight: 400;
}

.shadow-box {
    box-shadow: var(--shadow);
    border-radius: 1.5rem;
}

/* table{
    margin-bottom: 0px!important;
  } */


.OnlineMeetingWrp,
.OfflineMeetingWrp,
.OfflineMeetingSummeryWrp {
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border: #ced4da solid 1px;
    margin-bottom: 0px;
}

.modal-header button {
    margin: 0px;
}

.modal-header button:hover {
    background: none !important;
}

.modal-content {
    border: var(--darkblue) solid 5px;
}

.modal-footer .btn-secondary {
    background: #ffffff !important;
    border: var(--darkblue) solid 1px;
    padding: 5px 10px;
    font-size: 13px;
    color: #000000;
}

.modal-footer .btn-primary {
    background: var(--darkblue) !important;
    padding: 5px 10px;
    font-size: 13px;
}

.loader-ecoindex {
    width: calc(6*30px);
    height: 50px;
    display: flex;
    color: var(--darkblue);
    filter: drop-shadow(30px 25px 0 currentColor) drop-shadow(60px 0 0 currentColor) drop-shadow(120px 0 0 currentColor);
    clip-path: inset(0 100% 0 0);
    animation: l12 2s infinite steps(7);
}

.loader-ecoindex:before {
    content: "";
    width: 30px;
    height: 25px;
    --c: no-repeat radial-gradient(farthest-side, currentColor 92%, #0000);
    background:
        var(--c) left /70% 70%,
        var(--c) right/20% 20%,
        var(--c) top 0 right 15%/20% 20%,
        var(--c) bottom 0 right 15%/20% 20%;
}

@keyframes l12 {
    100% {
        clip-path: inset(0 -30px 0 0)
    }
}


/* responsive css */

@media (max-width: 768px) {
    .event-details-navbar ul li {
        display: block !important;
    }

    .event-details-navbar ul li a.active {
        background: none;
        color: #ffffff;
    }
}

.loaderwrpOuter {
    width: 100px;
    margin: 100px auto;
    background: #ffffff;
    text-align: center;
    border: #ced4da solid 1px;
    padding: 10px;
}

.loaderwrp {
    border: 10px solid #f3f3f3;
    border-top: 10px solid var(--darkblue);
    border-bottom: 10px solid var(--darkblue);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    margin: 0 auto;

}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.ML10px {
    margin-left: 10px;
}