body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f8f9fa;
    color: #212529;
  }
  
  .privacy-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    animation: fadeIn 1s ease-in-out;
  }
  
  .privacy-title {
    text-align: center;
    color: rgb(48, 82, 99);
    margin-bottom: 30px;
  }
  
  .privacy-section {
    margin-bottom: 20px;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    animation: slideIn 0.8s ease-in-out;
  }
  
  .section-title {
    font-size: 1.5em;
    color: rgb(48, 82, 99);
    margin-bottom: 10px;
  }
  
  .section-content {
    font-size: 1em;
    line-height: 1.6;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideIn {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  