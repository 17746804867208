.pre-planning {
  display: flex;
  background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  height: 100%;
}

.pre-planning-content {
  background-color: var(--aqua);
  border: 1px solid var(--darkbluesemi);
  border-top: none;
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  padding: 3rem;
}

.access-restricted {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  padding: 20px;
  text-align: center;
  border: 2px solid var(--darkbluesemi);
  border-radius: 5px;
  background-color: #f9f9f9;
  margin: 20px auto;
  width: fit-content;
}