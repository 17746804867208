.material-consumption-form h3 {
  font-family: "Raleway", sans-serif;
  font-size: 1.2em;
  color: var(--greyLight-3)!important;
}

.material-consumption-form .table {
  border: 1px solid #eee;
}

.material-consumption-form th {
  background-color: var(--darkblue);
  color: var(--white);
  font-size: 15px;
  font-weight: 300;
  border-right: 1px solid #fff;

  &:first-child {
    border-top-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-right: none
  }
}

.material-consumption-form td {
  color: var(--darkblue);
  font-size: 13px;
}

.material-consumption-form input,
.material-consumption-form select {
  border-color: var(--darkbluesemi);
  border-radius: 5px;
  color: #888;
  height: 30px;
  padding: 0 15px;
}

.material-consumption-form input[type="file"] {
  padding: 1.5px;
}

.PlusBtnHead .fa-plus-circle, .PlusBtnHead .fa-minus-circle {
  color: var(--white);
  cursor: default;
}

.PlusBtn .fa-plus-circle, .MinusBtn .fa-minus-circle {
  color: var(--darkblue)!important;
  cursor: pointer;
}

.table-responsive {
  overflow-x: auto;
}

@media (max-width: 768px) {
  .table {
    width: 100%;
    min-width: 600px; /* Or a suitable width for your table */
  }
}
