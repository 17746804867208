.footer {
  height: 500px;
  padding: 130px 50px;
  width: 100%;
  background-image: url('./assets/Footer_img1.png');
  background-size: cover;
  background-color: #bbe6ec;
  background-position: center; 
  background-repeat: no-repeat; 
  color: rgb(48, 82, 99); 
  position: relative; 
  overflow-x: hidden;
}

@media(max-width: 767px) {
  .footer {
    height: auto;
    background-position: bottom; 
    background-size: contain; 
  }
}

.footer-items {
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 94%;
  gap: 40px; 
}

@media(min-width: 768px) {
  .footer-items {
    flex-direction: row;
    justify-content: space-between;
    gap: 150px; 
    margin-top: -50px;
    width: 94%;
  }
}

.logo-section {
  max-width: 300px;
}

.logo {
  width: 85px;
  margin-bottom: 20px;
}

.description {
  font-size: 14px;
  color: #5d6470;
  line-height: 1.6;
}

.social-icons {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.social-link {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6B7280;
}

.links-section {
  display: flex;
  flex-direction: column; 
  align-items: center;   
  gap: 20px;           
  flex-grow: 1; 
}

@media(min-width: 768px) {
  .links-section {
    justify-content: flex-start;
    gap: 10px; 
    flex-wrap: wrap;
    flex-direction: row;
  }
}

.link-group {
  max-width: 250px;
  margin-left: 0px; 
  text-align: center; 
}

@media(min-width: 768px) {
  .link-group {
    margin-left: 30px;
    text-align: left; 
  }
}

.link-group-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
}

.link-list {
  list-style: none;
  padding: 0;
}

.link {
  color: #6B7280;
  text-decoration: none;
  display: block;
  margin-bottom: 10px;
}

.link:hover {
  text-decoration: underline;
}

.footer-text {
  text-align: center; 
  font-size: 14px;
  color: #6B7280;
  margin-top: 8px;
  position: absolute;
  bottom: 10px;
}

@media(min-width: 768px) {
  .footer-text {
    text-align: left;
  }
}