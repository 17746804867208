:root {
  /*colors*/
  --indigo: #272846;
  --indigosemi: #27284679;
  --white: #fefeff;
  --aqua: #e4e8f6;
  --aquasemi: #e3f7f630;
  --greyLight-1: #E4EBF5;
  --greyLight-2: #c8d0e7;
  --greyLight-3: #454d65;
  --greyDark: #9baacf;
  /* --skyblue: #b5cfd3; */
  --skyblue: #bbe6ec;
  --skybluesemi: #bbe6ec30;
  --darkblue: #2e688d;
  --darkbluesemi: #2e688d88;
  --darkbluesemi2: #2e688dee;
  --darkblueinvi: #2e698d00;
  --darkteal: #256385;
 

  /* fonts */
  --fdesc: "Rubik", "lato", sans-serif;
  --fhead: "Raleway", serif;
  --fs0: 4.2rem;
  --fs1: 3.6rem;
  --fs2: 2.4rem;
  --fs3: 2.2rem;
  --fs4: 2rem;
  --fs5: 1.5rem;
  --fs6: 1.4rem;
  --fs7: 1.3rem;

  /* shadows */
  --shadow: -.5rem -.5rem 1rem hsl(0 0% 100% / .75), .5rem .5rem 1rem hsl(0 0% 50% / .5);
  --shadow1: 0 2px 8px hsla(0, 0%, 0%, 0.195);
  --shadow2: 2px 4px 8px hsla(215, 20%, 16%, 0.15);

  /* transitions */
  --tran1: 0.25s ease;
  --tran2: 0.5s ease;
  --tran3: 1s ease;
  --cubicin: cubic-bezier(0.51, 0.03, 0.64, 0.28);
  --cubicout: cubic-bezier(0.33, 0.85, 0.4, 0.96);
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


a {
  text-decoration: none;
  color: inherit;
}

input,
button {
  background: none;
  border: none;
  font: inherit;
}

.button {
  cursor: pointer;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

li {
  list-style: none;
}

html {
  font-family: var(--fdesc);
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  background-color: var(--white);
  color: var(--darkteal);
  font-size: 1.6rem;
  line-height: 1.75;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: hsl(0, 4%, 90%);
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #2e698ddc;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #2e698d;
}

.landing-container {
  height: 100vh;
  width: 100%;
  overflow-y: scroll;

  scroll-snap-type: y mandatory;
}

.slides {
  display: flex;
  flex-direction: column;
  justify-content: center;
  scroll-snap-align: start;
  height: 100vh;
  width: 100%;
}



.slide1 {
  background-image: linear-gradient(left,
      var(--skyblue),
      var(--skyblue) 70%,
      transparent 70%,

      transparent 100%);
  background-image: -webkit-linear-gradient(left,
      var(--skyblue),
      var(--skyblue) 70%,
      transparent 70%,
      transparent 100%);
}

.slide2 {
  background-image: linear-gradient(to right,
      transparent 30%,
      var(--skyblue) 30%,
      var(--skyblue) 100%),
    url("./assets/backanim.png");
  background-position: left;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.slide3 {
  background-image: linear-gradient(to left,
      transparent 30%,
      var(--skyblue) 30%,
      var(--skyblue) 100%),
    url("./assets/backanim2.png");
  background-position: left;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}



.slide4 {
  background-image: linear-gradient(to right,
      transparent 30%,
      var(--skyblue) 30%,
      var(--skyblue) 100%),
    url("./assets/backanim.png");
  background-position: left;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.header {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  padding-block: var(--fs1);
}

.navbar-left {
  flex: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: var(--fs1);
}

.navbar-right {
  flex: 30%;
}

.logo {
  width: 100px;
  height: 40px;
}

.hamburger-menu {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 20px;
  height: 15px;
  margin-top: 15px;
  cursor: pointer;
  margin-right: 10px;
 
}

.hamburger-menu .bar {
  height: 2px;
  background-color: var(--darkblue);
  margin-top: -5px;
  transition: 0.3s;
}

.navlist {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.navitem {
  display: inline-block;
}

.navlinks {
  text-transform: uppercase;
  font-size: var(--fs5);
  font-weight: 400;
  letter-spacing: 0.3rem;
  transition: var(--tran1);
}

.navlinks:hover {
  color: var(--white);
  text-shadow: var(--indigosemi) 0px 0px 5px;
}


.btn {
  font-size: var(--fs5);
  font-weight: 500;
  border-radius: 50px;
  padding: 8px 20px;
  cursor: pointer;
}

.btn_light {
  width: 100px;
  background: #36454F;
  color: white;
  outline: 1px solid var(--darkblueinvi);
  outline-offset: -1px;
}

.btn_light:hover {
  outline-color: var(--darkblue);
  background-color: var(--aqua);
  color: var(--indigo);
}


@media (max-width: 768px) {
  .navlist {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 80px;
    right: 10px;
    background-color: var(--white);
    width: 250px;
   
    padding: 20px;
    transition: all 0.3s ease-in-out;
  }

  .navlist.open {
    display: flex;
  }

  .hamburger-menu {
    display: flex;
    margin-left: -20px;
  }

  .navbar-left {
    flex: 1;
    justify-content: space-between;
  }

  .navbar-right {
    flex: 0;
  }

  .btn_light {
    position: relative;
    left: 0;
  }
}

.login-btn {

  padding: 0 15px;
  display: flex;
  justify-content: right;
}

.btn {
  margin: 0;
  text-align: center;
  font-size: var(--fs5);
  font-weight: 500;
  border-radius: 50px;
  padding: 8px 20px;
  transition: var(--tran2);
  cursor: pointer;
}

.btn_light {
  width: 100px;
  position: relative;
  left: -40px;
  background: #36454f;
  color: white;
  outline: 1px solid var(--darkblueinvi);
  outline-offset: -1px;
  border-radius: 50px;

  &:hover {
    outline-color: var(--darkblue);
    background-color: var(--aqua);
    color: var(--indigo);
  }
}

.btn-dark {
  width: 135px;
  padding-left: 50px;
  background-color: var(--darkblue);
  color: var(--skyblue);

  &:hover {
    background-color: var(--indigo);
    color: var(--white);
  }
}

.main {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.main-text {
  flex: 45%;
  padding: 0% 5% 10% 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-text h1 {
  text-transform: uppercase;
  font-family: 'Oswald';
  color: #36454F;
  font-size: 48px;
  font-weight: 600;
}

.main-text h3 {
  font-size: 20px;
  margin-top: 10px;
}

button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  font-size: 16px;
  font-family: 'Quicksand';
}

.schedule-button {
  margin-top: 50px;
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #282936;
  color: white;
  font-size: 16px;

  border-radius: 20px;
  transition: white 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  font-family: 'Quicksand', sans-serif;

}

.schedule-button:hover {
  background-color: #282936;
  transform: translateX(-5px);

}

.schedule-button .arrow {
  display: none;
  width: 20px;
  height: 20px;
  fill: #ffffff;
  opacity: 0;

  transform: translateX(-40px) rotate(-45deg);

  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

}

.schedule-button:hover .arrow {
  display: inline-block;
  opacity: 1;

  animation: slide-in 0.4s forwards;
}

@keyframes slide-in {
  from {
    transform: translateX(-60px) rotate(-45deg);
    opacity: 0.1;

  }

  to {
    transform: translateX(10px) rotate(-45deg);
    opacity: 1;

  }
}

.input-row {
  display: flex;
  justify-content: space-between;
}

.half-width {
  width: 48%;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: brightness(70%);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.popup-content {
  background: url('./assets/pricingback.png');
  background-size: contain;
  padding: 30px;
  border-radius: 10px;
  width: 600px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  animation: slideDown 0.4s ease;
  position: relative;
}

@keyframes slideDown {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 50px;
  font-size: 40px;
  cursor: pointer;
  color: #000000;
}

.close-icon:hover {
  color: #333;
}

.popup-title {
  margin-bottom: 20px;
  font-size: 26px;
  font-family: 'Oswald';
  margin-bottom: 30px;
  color: #333;
}

.popup-form .input-group {
  margin-bottom: 15px;
}

.popup-form label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #555;
}

.popup-form input,
.popup-form textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  outline: none;
  transition: border 0.3s ease;
  background-color: white;
}

.popup-form input:focus,
.popup-form textarea:focus {
  border-color: #007bff;
}

.popup-form textarea {
  resize: none;
  height: 80px;
}

.popup-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.submit-button {

  padding: 10px 20px;
  width: 800px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  background-color: #282936;
  color: white;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #2e688d;
}
#scrollToTopBtn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #3d3d3d; 
  color: white;
  font-size: 2rem;
  border-radius: 50%;
  box-shadow: var(--shadow);
  display: none; 
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  z-index: 100; 
}

#scrollToTopBtn.show {
  display: flex !important;
}


@media (max-width: 768px) {
  

  
  .popup-content {
    width: 100%; 
    padding: 16px;
  }
 
  .half-width {
    width: 100%; 
  }

  .popup-form input,
  .popup-form textarea {
    width: 100%;
    padding: 6px;
    border-radius: 5px;
  gap: 2px;
  }
  
  .popup-buttons {
    flex-direction: column;
    width: 100%;
  }

  .close-icon {
    top: 10px;
    right: 20px; 
    font-size: 30px; 
  }
  .submit-button {
    width: 100%;
    max-width: 100%;
  }
}


@media (max-width: 480px) {
  

  .popup-content {
    padding: 12px; 
    max-width: 350px;
  }
  .submit-button {
    font-size: 14px; 
  }

  .close-icon {
    font-size: 28px; 
  }
}

.slide-right-content {
  align-content: center;
  padding: 15rem;
}

.form1 {
  flex: 60%;
}


.slide-left-content {
  align-content: center;
  padding: 15rem;
}


.heading1 {
  text-align: left;
  color: var(--indigo);
  font-size: var(--fs0);
  font-family: 'Oswald';
  font-weight: 400;
  padding-bottom: 3rem;
  width: 100%;
}

.slide-right-content p {
  text-align: justify;
  width: 100%;
}

.slide-right-content p span {
  font-size: 18px;
}


.slide-left-content p span {
  font-size: 18px;
}

.slide-left-content p {
  text-align: justify;
  width: 100%;
}

.image {
  display: flex;
  flex: 40%;
  justify-content: center;
  padding: 10px;

}

.image img {
  margin-top: 0px;
  height: 100%;
  width: 700px;
  object-fit: contain;
}

.rectangle-box {
  position: relative;
  margin-top: 15px;
  margin-left: -40px;
  background-color: rgb(255, 255, 255);
  width: 520px;
  overflow: hidden;
  clip-path: polygon(40px 0%,
      100% 0,
      100% calc(100% - 40px),
      calc(100% - 30px) 100%,
      0 100%,
      0% 30px);
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;

  height: 692px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 5px 8px 0px;

}

.rectangle-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(./assets/anim/eventback.png);
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 10;
  opacity: 1;
}

.paper-img {
  margin-top: -20px;
  height: 380px;
  padding: 10px;
  margin-left: -150px;
  width: auto;
}

.wrong-img {
  height: 200px;
  margin-top: -390px;
  margin-left: -320px;
}

.reg-img {
  margin-top: -30px;
  height: 400px;
  margin-left: -120px;
  width: auto;
}

.right-img {
  height: 120px;
  margin-top: -360px;
  margin-left: -290px;
}

.bottle-img {
  margin-top: 70px;
  margin-left: -120px;
  height: 320px;
  width: auto;
}

.meal-img {
  margin-left: -50px;
  margin-top: 80px;
  height: 280px;
}

.recbanner-img {
  height: 150px;
  margin-top: -400px;
  margin-left: -320px;
}

.banner-img {
  margin-top: 25px;
  height: 320px;
  margin-left: -120px;
  width: auto;
}

.ev-icon {
  height: 120px;
  margin-top: -40px;
  margin-left: -240px;
}

.ev-img {
  margin-top: -240px;
  margin-left: -50px;
  height: 520px;
  width: auto;
}

.bulb-img {
  margin-top: -10px;
  height: 320px;
  margin-left: -100px;
  width: auto;
}

.energy-img {
  height: 130px;
  margin-left: -250px;
  width: auto;
}

.monitor-img {
  height: 290px;
  margin-top: -120px;
  margin-left: -60px;
}

.text-paper {
  margin: 65px;
  color: rgb(0, 55, 49);
  text-align: left;
  font-size: 34px;
  font-weight: 900;
}

.sub-text-slide1 {
  margin: 65px;
  margin-top: -55px;
  font-size: 15px;
  text-align: left;


}

.reg-img,
.paper-img,
.bottle-img,
.wrong-img,
.right-img,
.recbanner-img,
.ev-img,
.bulb-img,
.energy-img,
.monitor-img,
.text-paper,
.ev-icon {
  position: relative;
  z-index: 1;
  opacity: 1;
  transition: all 2.5s cubic-bezier(0.105, 0.82, 0.165, 1);
}


.text-slide2 {
  margin: 65px;

  color: rgb(0, 55, 49);
  text-align: right;
  font-size: 34px;
  font-weight: 900;
}

.sub-text-slide2 {
  margin: 65px;
  margin-top: -55px;
  font-size: 15px;
  text-align: right;

}

.bag-img {
  height: 400px;
  margin-top: -50px;
  margin-left: -20px;
}

.cycle-img {
  margin-top: -120px;
  height: 450px;
}

.climate-img {
  height: 160px;
  margin-top: -220px;
  margin-left: -290px;
}

.clothes-img {

  height: 400px;
  margin-top: -40px;
}

.AC-img {
  height: 400px;
  margin-top: -80px;
}

.train-img {
  height: 540px;
  width: 540px;
  margin-top: -150px;
}

.low-img {
  height: 150px;
  margin-top: -220px;
  margin-left: -290px;
}

.food-img {
  height: 350px;
  margin-top: 5px;
  margin-left: -50px;
}

.shopping-img {
  margin-top: -40px;
}

.rrr-img {
  margin-top: -75px;
}

.plant-img {
  margin-top: -100px;
  height: 400px;
}

.bag-img,
.cycle-img,
.AC-img,
.train-img,
.low-img,
.food-img,
.shopping-img,
.rrr-img,
.plant-img,
.climate-img,
.clothes-img {
  position: relative;
  z-index: 1;
  opacity: 1;
  transition: all 2.5s cubic-bezier(0.105, 0.82, 0.165, 1);
}


.text-slide3 {
  margin: 65px;

  color: rgb(0, 55, 49);
  text-align: left;
  font-size: 34px;
  font-weight: 900;
}

.sub-text-slide3 {
  margin: 65px;
  margin-top: -55px;
  font-size: 15px;
  text-align: left;

}

.renew-img {
  height: 550px;
  margin-top: -150px;
  margin-left: -140px;
}

.efficiency-img {
  height: 500px;
  margin-top: -120px;
  margin-left: -100px;
}

.meet-img {
  height: 250px;
  margin-top: -80px;
  margin-left: -190px;
  z-index: 2000;
}

.evcar-img {
  height: 290px;
  margin-top: -120px;
  margin-left: -420px;
}

.shipping-img {
  height: 400px;
  margin-top: -140px;
  margin-left: -80px;
}

.supply-img {
  height: 400px;
  margin-top: -90px;
  margin-left: -80px;
}

.plasticbag-img {
  height: 460px;
  margin-top: -130px;
  margin-left: -80px;
}

.wastemethod-img {
  height: 440px;
  margin-top: -110px;
  margin-left: -40px;
}

.renew-img,
.efficiency-img,
.travel-img,
.shipping-img,
.supply-img,
.plasticbag-img,
.wastemethod-img {
  position: relative;
  z-index: 1;
  opacity: 1;
  transition: all 2.5s cubic-bezier(0.105, 0.82, 0.165, 1);
}




.subheading1 {
  color: var(--darkblue);
  font-size: var(--fs3);
  font-family: var(--fdesc);
  font-weight: 400;
  margin-bottom: 40px;
  padding: 0;
  line-height: 85%;
}

.input1 {
  background-color: var(--white);
  border-radius: 8px;
  padding: 5px 15px;
  margin-top: 8px;
  width: 100%;
  color: var(--darkblue);
  font-size: var(--fs6);

  &:focus {
    outline: none;
  }

  &::placeholder {
    opacity: 0.5;
  }
}

.frgtpass-signin {
  display: flex;
  justify-content: space-between;
}

.frgtpass-signin>a,
button {
  margin-top: 15px;
  display: inline-block;
}

.frgtpass-signin a {
  letter-spacing: 1.5px;
  padding: 3px;
  transition: var(--tran1);

  &:hover {
    color: var(--indigo);
  }
}


.new-user {
  text-align: center;
}

.new-user .form-signup {
  font-weight: 500;
  color: var(--indigo);
  cursor: pointer;
}

.new-user p {
  display: inline-block;
  padding-right: 8px;
}


.slide5 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide6 {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 1px solid red;
  overflow: hidden;
}


.footer .heading1 {
  color: var(--white);
  font-size: var(--fs2);
  margin-top: 40px;
}

.footer .fa {
  padding: 12.5px;
  margin: 5px;
  font-size: 15px;
  width: 40px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  background-color: var(--white);
  color: var(--indigo);

  &:hover {
    color: var(--white);
    background-color: var(--indigo);
  }
}

/* .modal {
  z-index: 1;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: var(--tran2);

  &.is-login-open {
    height: 100vh;
    width: 100%;

    .modal-container-login {
      opacity: 1;
      transition-duration: 0.6s;
      pointer-events: auto;
      transform: translateY(0) scale(1);
    }
  }

  &.is-signup-open {
    height: 100vh;
    width: 100%;

    .modal-container-signup {
      opacity: 1;
      transition-duration: 0.6s;
      pointer-events: auto;
      transform: translateY(0) scale(1);
    }
  }
}

.modal-container {
  padding: 100px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  width: 100%;
  height: 90vh;
  border-radius: 10px;
  overflow: hidden;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  transition-duration: 0.3s;
  background: url("./assets/bglayer.png");
  transform: translateY(100px) scale(0.4);
  transition: transform 300ms;
}

.close {
  position: absolute;
  right: 25px;
  top: 5px;
  color: #2e698d80;
  font-size: 35px;
  font-family: "raleway", sans-serif;
  cursor: pointer;
  transition: var(--tran1);

  &:hover {
    font-weight: 500;
    color: #2e688df0;
  }
}

.modal-container>img {
  width: 125px;
  height: 50px;
  margin-bottom: 50px;
}

.modal-input {
  border: 1px solid #00000020;
} */

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@media screen and (max-width: 768px) {
  .navlist {
    flex-direction: column;
  }

  .navitem {
    display: block !important;
  }

  .header {
    top: 0rem;
  }

  .main {
    flex-direction: column;
    height: auto;
  }

  .main-text {
    flex: 100%;
    width: 100%;
  }

  .image {
    flex: 100%;
    width: 100%;
    margin-left: 10px;
  }

  .slide-right-content, .slide-left-content {
    flex: 100%;
    width: 100%;
    padding: 3rem;

  }

  .landing-container {
    height: auto;
  }

  .slides {
    height: auto;
  }

  .slide1, .slide2, .slide3, .slide4, .slide5, .slide6 {
    background-image: none;
    background-color: var(--skyblue);
  }


}