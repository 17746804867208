.energy-consumption label {
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    color: var(--darkblue);
    font-size: 1.3rem;
}

.energy-consumption input,
.energy-consumption select {
    border-color: var(--darkbluesemi);
    border-radius: 5px;
    color: #888;
    height: 35px;
    padding: 0 15px;
}

.save-button,
.next-button {
    padding: 0px 20px;
    width: 160px;
    border-radius: 3px;
    border: 1px solid var(--darkblue);
    color: var(--darkblue);
    transition: var(--tran2);
}


.next-button {
    margin-left: 10px;
}


.save-button:hover,
.next-button:hover {
    color: #ffffff !important;
    background-color: var(--darkblue);
}